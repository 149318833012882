import { createTheme } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'
import purple from '@material-ui/core/colors/purple'

const primaryBlue = blue[700]
const accentBlue = blue.A200
const darkBlue = blue[900]
const primaryPurple = purple[500]
const accentPurple = purple.A200
const darkPurple = purple[900]

// Colors
// const black = "#000000";
// const lightBlack = "#1F1E1E";
// const lighterBlack = "#2C2C2C";
const white = '#FEFEFE'
const gray = '#4A4A4A'
const orange = '#FC982B'
// const blue = "#0C87F2";
// const green = "#5DC65D";
const yellow = '#FAE453'
// const purple = "#593c77";

// https://colorpalettes.net/color-palette-2236/
// const darkSand = '#a67d63';
// const lightSand = '#bcada3';
const whiteSand = '#f0f1eb'
const greenWater = '#99cab5'
const blueSky = '#abc8e7'

export const overridings = {
  name: 'Light Theme',
  palette: {
    awesomeColors: {
      primary: '#fe6b8b',
      secondary: '#ff8e53',
    },
    primary: {
      light: accentBlue,
      main: primaryBlue,
      dark: darkBlue,
      contrastText: '#fff',
    },
    secondary: {
      light: accentPurple,
      main: primaryPurple,
      dark: darkPurple,
      contrastText: '#fff',
    },
    background: {
      default: white,
      paper: whiteSand,
      appbar: blueSky,
      toolbar: blue,
    },
    colors: {
      white,
      gray,
      orange,
      blue,
      green: greenWater,
      yellow,
      purple: primaryPurple,
    },
  },
}

export default createTheme(overridings)
