import process from 'process'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

// import reportWebVitals from './reportWebVitals';
import { ContextProvider } from './context/Context'
import { BrowserRouter } from 'react-router-dom'
import ThemeProvider from './themes/ThemeProvider'
import LightTheme from './themes/LightTheme'
import DarkTheme from './themes/DarkTheme'
import { getUser, errorIgnoreList } from './utils'

import { serviceWorkerRegister, clearCache } from './serviceWorkerToolkit'
import PWAPrompt from 'react-ios-pwa-prompt'

// Clear browser cache for service worker network first caching strategy
clearCache()

// In development ignore certain errors
if (process.env && process.env.REACT_APP_IGNORE_WARNINGS === 'true') {
  errorIgnoreList()
}

// Load user specific theme
let user = getUser()
let theme = user && user.theme === 'dark' ? DarkTheme : LightTheme

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ContextProvider>
        <ThemeProvider theme={theme}>
          <App />
          <PWAPrompt
            promptOnVisit={1}
            timesToShow={3}
            copyClosePrompt="Close"
            permanentlyHideOnDismiss={false}
            copyShareButtonLabel="1) Press 'Share' button and scroll down"
          />
        </ThemeProvider>
      </ContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)

serviceWorkerRegister()

/* 
  // TODO: Research cross origin isolated security currently CORS is handled in src/setupProxy.js to trusted domains
  // and not by adding crossorigin attribute to img, font and video tags for resources on toher domains
  
  // Scheduler.development.js:298 [Deprecation] SharedArrayBuffer will require cross-origin isolation as of M91, around May 2021. 
  // See https://developer.chrome.com/blog/enabling-shared-array-buffer/ for more details.

  // See https://web.dev/coop-coep/ for detailed explanation of headers related to cross origin isolated required for powerful features  
  // Cross-Origin-Opener-Policy-Report-Only: same-origin
  // Cross-Origin-Embedder-Policy-Report-Only: require-corp; report-to="coep"
  // report-to: {"group": "coep", "max-age": 86400, "endpoints": [ {"url": "https://dev.storybook.events/report"} ]}

  if (window.crossOriginIsolated) {
    console.log('Cross origin isolated');
  }
  else {
    console.log('Not running cross origin isolated');
  }
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
