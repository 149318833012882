// Service worker tutorials
// See https://caniuse.com/serviceworkers
// See https://www.youtube.com/watch?v=9_samZ3VQOU&list=PLa2ewYwGH_YM7ih-RMEQ3e-mfR6sBO-rk&index=1
// See https://javascript.plainenglish.io/creating-a-browser-agnostic-pwa-install-button-41039f312fbe
// See https://github.com/chrisdancee/react-ios-pwa-prompt

import * as React from 'react'

// Service worker registration
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { DateTime } from 'luxon'

// Clear window.caches to make sure latest app version is loaded
// Only in offline mode cache will be used.
export function clearCache() {
  if (navigator.onLine) {
    caches.keys().then(function (keys) {
      keys.forEach(function (key) {
        caches.delete(key)
      })
    })
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

export function serviceWorkerRegister() {
  // We can detect that a PWA is not installable by checking if the features required for that function are available. If not, we can be certain there�s no PWA functionality on that browser and hide our button altogether.
  if (!('serviceWorker' in navigator)) {
    console.log('Progressive Web App (PWA) is installable.')
  }
  serviceWorkerRegistration.register()

  // If the app is open in its own window, we know the app is already installed
  window.addEventListener('DOMContentLoaded', function () {
    if (
      navigator.standalone ||
      window.matchMedia('(display-mode: standalone)').matches ||
      window.matchMedia('(display-mode: fullscreen)').matches ||
      window.matchMedia('(display-mode: minimal-ui)').matches
    ) {
      console.log('PWA already installed, hide install button.')
      const butInstall = document.getElementById('butInstall')

      if (butInstall) {
        butInstall.style.display = 'none'
      }
    }
  })

  // Add listener after app installation on desktop, that will make app open in its own window right away.
  window.addEventListener('DOMContentLoaded', function () {
    window.matchMedia('(display-mode: standalone)').addListener(function (e) {
      if (e.matches) {
        console.log('PWA installed in display-mode: standalone')

        const butInstall = document.getElementById('butInstall')
        if (butInstall) {
          butInstall.style.display = 'none'
        }
      }
    })
    window.matchMedia('(display-mode: fullscreen)').addListener(function (e) {
      if (e.matches) {
        console.log('PWA installed in display-mode: fullscreen')
        const butInstall = document.getElementById('butInstall')
        if (butInstall) {
          butInstall.style.display = 'none'
        }
      }
    })
    window.matchMedia('(display-mode: minimal-ui)').addListener(function (e) {
      if (e.matches) {
        console.log('PWA installed in display-mode: minimal ui')
        const butInstall = document.getElementById('butInstall')
        if (butInstall) {
          butInstall.style.display = 'none'
        }
      }
    })
  })

  const butInstall = document.getElementById('butInstall')

  let deferredPrompt
  const addBtn = document.querySelector('.add-buttonFirefox')

  window.addEventListener('beforeinstallprompt', (e) => {
    console.log('Event listener beforeinstallprompt')

    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault()
    // Stash the event so it can be triggered later.
    deferredPrompt = e
    // Update UI to notify the user they can add to home screen
    if (addBtn) {
      addBtn.style.display = 'none'

      addBtn.addEventListener('click', () => {
        // hide our user interface that shows our A2HS button
        addBtn.style.display = 'none'
        // Show the prompt
        deferredPrompt.prompt()
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt')
          } else {
            console.log('User dismissed the A2HS prompt')
          }
          deferredPrompt = null
        })
      })
    }
  })

  // Save to homescreen button for Iphone
  window.addEventListener('appinstalled', (event) => {
    console.log('Event listener appinstalled')
    // console.log(event);

    // 'appinstalled=true;expires=Tue, 30 Mar 2023 23:59:59 GMT;path=/';
    // Make expire timestamp in UTC format, 3 days ahead in time
    const utcStr = DateTime.now().plus({ days: 3 }).toJSDate().toUTCString()
    document.cookie = 'appinstalled=true;expires=' + utcStr + ';path=/'

    window.deferredPrompt = null
    const butInstall = document.getElementById('butInstall')
    if (butInstall) {
      butInstall.style.display = 'none'
    }
  })

  const cancelIos = document.getElementById('cancelIos')
  if (cancelIos) {
    cancelIos.addEventListener('click', function () {
      document.getElementById('iosPWA').style.display = 'none'
    })
    if (butInstall) {
      butInstall.addEventListener('click', function () {
        if (
          [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod',
          ].includes(navigator.platform)
        ) {
          document.getElementById('iosPWA').style.display = 'block'

          return true
        }
        // iPad on iOS 13
        else if (
          navigator.userAgent.includes('Mac') &&
          'ontouchend' in document
        ) {
          document.getElementById('iosPWA').style.display = 'block'
          return true
        } else {
          if (deferredPrompt !== undefined) {
            // The user has had a postive interaction with our app and Chrome
            // has tried to prompt previously, so let's show the prompt.
            deferredPrompt.prompt()

            // Follow what the user has done with the prompt.
            deferredPrompt.userChoice.then(function (choiceResult) {
              console.log(choiceResult.outcome)

              if (choiceResult.outcome === 'dismissed') {
                console.log('User cancelled home screen install dismissed')
              } else {
                console.log('User added to home screen')
              }

              // Clear prompt
              deferredPrompt = null
            })
          }
        }
      })
    }
  }

  // Delete cache and reload
  window.addEventListener('activate', (event) => {
    console.log('Event listener activate')
    event.waitUntil(
      caches
        .keys()
        .then((cacheNames) => {
          return Promise.all(
            cacheNames.map((cacheName) => {
              return caches.delete(cacheName)
            }),
          )
        })
        .then(() => {
          console.log('App will reload')
          window.location.reload()
        }),
    )
  })
}

// interface IBeforeInstallPromptEvent extends Event {
//   readonly platforms: string[];
//   readonly userChoice: Promise<{
//     outcome: "accepted" | "dismissed";
//     platform: string;
//   }>;
//   prompt(): Promise<void>;
// }

export function useAddToHomescreenPrompt() {
  const [prompt, setState] = React.useState()

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt()
    }

    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event',
      ),
    )
  }

  React.useEffect(() => {
    const ready = (e) => {
      e.preventDefault()
      setState(e)
    }

    window.addEventListener('beforeinstallprompt', ready)

    return () => {
      window.removeEventListener('beforeinstallprompt', ready)
    }
  }, [])

  return [prompt, promptToInstall]
}
