import { createTheme } from '@material-ui/core/styles'
import purple from '@material-ui/core/colors/purple'

const primaryBlack = '#2C2C2C'
const accentBlack = '#1F1E1E'
const darkBlack = '#000000'
const primaryPurple = purple[500]
const accentPurple = purple.A200
const darkPurple = purple[900]

// Colors
// const black = "#000000";
// const lightBlack = "#1F1E1E";
// const lighterBlack = "#2C2C2C";
const white = '#FFFFFF'
const gray = '#4A4A4A'
const orange = '#FC982B'
const blue = '#0C87F2'
// const green = "#5DC65D";
const yellow = '#FAE453'
// const purple = "#593c77";

// https://colorpalettes.net/color-palette-2236/
// const darkSand = '#a67d63';
// const lightSand = '#bcada3';
// const whiteSand = '#f0f1eb';
const greenWater = '#99cab5'
const blueSky = '#abc8d7'

export const overridings = {
  name: 'Dark Theme',
  palette: {
    primary: {
      light: accentBlack,
      main: primaryBlack,
      dark: darkBlack,
      contrastText: '#fff',
    },
    secondary: {
      light: accentPurple,
      main: primaryPurple,
      dark: darkPurple,
      contrastText: '#fff',
    },
    background: {
      default: darkBlack,
      paper: accentBlack,
      appbar: blueSky,
      toolbar: blue,
    },
    colors: {
      white,
      gray,
      orange,
      blue,
      green: greenWater,
      yellow,
      purple: primaryPurple,
    },

    type: 'dark',
  },
}

/*

// Colors
const black = "#000000";
const lightBlack = "#1F1E1E";
const lighterBlack = "#2C2C2C";
const white = "#FFFFFF";
const gray = "#4A4A4A";
const orange = "#FC982B";
const blue = "#0C87F2";
const green = "#5DC65D";
const yellow = "#FAE453";
const purple = "#593c77";

export const overridings = {
  name: "Dark Theme",
  palette: {
    primary: {
      main: black,
      light: gray,
      contrastText: white,
    },
    secondary: {
      main: lightBlack,
      light: lighterBlack,
      contrastText: white,
    },
    background: {
      default: black,
      paper: lightBlack,
    },
    colors: {
      orange,
      blue,
      green,
      yellow,
      purple,
    },

    type: "dark",
  },
  typography: {
    fontSize: 14,
    fontFamily: "Benton Sans Regular",
    spacing: {
      gutterSmall: 2,
    },
    body1: {
      "@media (max-width: 960px)": {
        fontSize: "0.9rem",
      },
    },
    body2: {
      "@media (max-width: 960px)": {
        fontSize: "0.9rem",
      },
    },
    h5: {
      "@media (max-width: 960px)": {
        fontSize: "1.4rem",
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            "Benton Sans Regular",
            "Benton Sans Medium",
            "Benton Sans Bold",
            "calibri",
            "Garamond",
            "Comic Sans MS",
          ].join(","),
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
};
*/
export default createTheme(overridings)
