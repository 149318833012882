// See https://www.w3.org/TR/webauthn-2/
// See https://stackoverflow.blog/2022/11/16/biometric-authentication-for-web-devs/

// See https://codelabs.developers.google.com/codelabs/webauthn-reauth#0
// See https://www.w3.org/TR/webauthn-2/#sctn-privacy-considerations-client

// Usernames in valid email format are rejected to avoid sharing that
// the email adress even exists.

import React, { useState, useContext, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Keyboard from '@material-ui/icons/Keyboard'
import Fingerprint from '@material-ui/icons/Fingerprint'
import Button from '@material-ui/core/Button'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import Link from '@material-ui/core/Link'
import '../App.css'
// cbor dependency uses lookbehind regexp that breaks Safari versions
// before 16.4, so we try the less common borc implementation for cbor
// import cbor from 'cbor';
import cbor from 'borc'
import base64url from 'base64url'
import { fetchWithTimeout } from '../utils.js'
import { Context } from '../context/Context'
import { withContext } from '../components/context-container'
import { DateTime } from 'luxon'

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  header: {
    width: '100%',
    textAlign: 'center',
  },
  box: {
    margin: 10,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    border: '1px solid #DDDDDD',
    borderRadius: 20,
    boxShadow: '5px 5px #EEEEEE',
    width: 300,
    backgroundColor: '#FFFFFF',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(1),
  },
  textField: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(2),
    borderRadius: 20,
  },
}))

function Login(props) {
  const passwordElement = useRef(null)

  const classes = useStyles()
  const [values, setValues] = useState({
    userId: 0,
    challenge: '',
    credentialId: '',
    email: '',
    username: '',
    password: '',
    showPassword: false,
    // token identification using fingerprint, pin, pattern
    showFingerprint: typeof PublicKeyCredential !== 'undefined',
    usernameHint: 'Please type e-mail or login username',
    passwordHint: 'Click eye icon for password hint',
  })
  const context = useContext(Context)
  const [statusText, setStatusText] = useState('')

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  // Get unique credential challenge from server using post because database is updated.
  const postUserCredentialChallenge = async (userId) => {
    const res = await fetchWithTimeout(
      '/api/v1/user/' + userId + '/credential/challenge',
      {
        method: 'POST',
        credentials: 'include',
      },
    )
    let json
    if (res) {
      json = await res.json()
      if (res.status === 200) {
        console.log(
          'TouchID challenge stored:/n',
          JSON.stringify(json, null, 2),
        )
        setStatusText('TouchID challenge stored')
      } else {
        console.log(
          'TouchID challenge not stored:/n',
          JSON.stringify(json, null, 2),
        )
        setStatusText('TouchID challenge not stored:' + json.message)
      }
    }
    return json
  }

  // Send public key to server for storage, where challenge and createdAt are
  // just used for verification, if this public/private pair credentials was
  // created with a given timeframe and used the proper challenge.
  const postUserCredentialPublicKey = async (
    userId,
    uniqueCredentialId,
    challenge,
  ) => {
    // Send fingerprint credentials to server
    // Store base64 url encode id, that can decoding can
    // be used with navigator.credentials.get() to later
    // find the credentials in the credential store
    let json = {
      credential: {
        publicKey: uniqueCredentialId,
        challenge: challenge,
        createdAt: DateTime.now().toISO(),
      },
    }
    const res = await fetchWithTimeout(
      '/api/v1/user/' + userId + '/credential/public-key',
      {
        method: 'POST',
        body: JSON.stringify(json),
        credentials: 'include',
      },
    )
    let result
    if (res) {
      result = await res.json()
      if (res.status === 200) {
        console.log(
          'TouchID public key stored:/n',
          JSON.stringify(result, null, 2),
        )
        setStatusText('TouchID public key stored')
      } else {
        console.log(
          'TouchID public key not stored:/n',
          JSON.stringify(result, null, 2),
        )
        setStatusText('TouchID public key not stored:' + result.message)
      }
    }
    return result
  }

  // https://webauthn.guide
  // https://github.com/fido-alliance/webauthn-demo
  // https://webauthn.me/introduction
  // NodeJS example
  // https://github.com/wallix/webauthn/blob/master/example/api/index.js
  // https://github.com/wallix/webauthn/blob/master/example/front/src/index.js
  // https://www.w3.org/TR/webauthn-3/

  // Challenge is a random string created on the server during signup
  // or during reset password request the challenge can be recreated
  const registerFingerprint = async (userId, email, username) => {
    if (typeof PublicKeyCredential !== 'undefined') {
      try {
        // Get or create unique credential challenge on the server
        const json = postUserCredentialChallenge(values.userId)
        let challenge = json.user.credentialChallenge
        // Create unique public key using this challenge created on the server
        const publicKeyCredentialCreationOptions = {
          challenge: Uint8Array.from(challenge, (c) => c.charCodeAt(0)),
          rp: {
            name: 'Storybook events',
            id: 'storybook.events', // domain name
          },
          user: {
            // Unique anonymous user id is stored on your device with
            // up to 64 characters used for authorization.
            // displayName is users full name or just firstname.
            id: Uint8Array.from('' + userId, (c) => c.charCodeAt(0)),
            name: email,
            displayName: username,
          },
          pubKeyCredParams: [{ alg: -7, type: 'public-key' }],
          authenticatorSelection: {
            authenticatorAttachment: 'platform', // cross-platform or platform (TPM)
          },
          timeout: 60000,
          attestation: 'direct',
        }

        let credential
        await navigator.credentials
          .create({
            publicKey: publicKeyCredentialCreationOptions,
          })
          .then(function (newCredentialInfo) {
            credential = newCredentialInfo
            setStatusText('TouchID credentials found')
          })
          .catch(function (err) {
            // No acceptable authenticator or user refused consent. Handle appropriately.
            setStatusText('TouchID not supported')
          })

        /* Example PublicKeyCredential {
              id: 'ADSUllKQmbqdGtpu4sjseh4cg2TxSvrbcHDTBsv4NSSX9...',
              rawId: ArrayBuffer(59),
              response: AuthenticatorAttestationResponse {
                  clientDataJSON: ArrayBuffer(121),
                  attestationObject: ArrayBuffer(306),
              },
              type: 'public-key'
            }
        */
        if (!credential) {
          throw new Error('Unable to create credentials')
        }

        /* Not implemented error
        navigator.credentials.store(credential).then(() => {
          alert("Credential stored in the user agent's credential manager.");
        }, (err) => {
          alert("Error while storing the credential: "+err.message);
        });
*/

        // Parsing the clientDataJSON
        const utf8Decoder = new TextDecoder('utf-8')
        const decodedClientData = utf8Decoder.decode(
          credential.response.clientDataJSON,
        )
        const clientDataObj = JSON.parse(decodedClientData)

        /*
          alert(JSON.stringify(clientDataObj,null,2)); 
          Returns:
              {
                "type": "webauthn.create",
                "challenge": "FDNSFHSDH...",
                "origin": "https://dev.storybook.events"
              }
        */
        console.log(JSON.stringify(clientDataObj, null, 2))

        // Parsing the attestationObject
        let decodedAttestationObj

        // cbor breaks Safari, so we use borc instead
        await cbor.decodeFirst(
          credential.response.attestationObject,
          function (error, obj) {
            if (error == null) {
              decodedAttestationObj = obj
            }
          },
        )
        // Parsing the authenticator data
        const { authData } = decodedAttestationObj
        // get the length of the credential ID
        const dataView = new DataView(new ArrayBuffer(2))
        const idLenBytes = authData.slice(53, 55)
        idLenBytes.forEach((value, index) => dataView.setUint8(index, value))
        const credentialIdLength = dataView.getUint16()
        // get the credential ID
        const credentialId = authData.slice(55, 55 + credentialIdLength)
        // get the public key object
        const publicKeyBytes = authData.slice(55 + credentialIdLength)
        // the publicKeyBytes are encoded again as CBOR
        let publicKeyObject

        // cbor breaks Safari, so we use borc instead
        await cbor.decodeFirst(publicKeyBytes.buffer, function (error, obj) {
          if (error == null) {
            publicKeyObject = obj
          }
        })

        alert('Credential ID', credentialId, '=', credential.id)
        console.log('Public key object valid', publicKeyObject !== undefined)

        await postUserCredentialPublicKey(userId, credential.id, challenge)

        setValues({
          ...values,
          credentialId: credential.id,
          challenge: clientDataObj.challenge,
        })
        setStatusText('TouchID registered')
      } catch (err) {
        console.log('Failed to register TouchID')
        console.error('ERROR:', err)
      }
    }
  }

  // eslint-disable-next-line
  const getFingerprint = async (credentialId, challenge) => {
    let json
    if (typeof PublicKeyCredential !== 'undefined') {
      try {
        let res = await navigator.credentials.get({
          publicKey: {
            challenge: Uint8Array.from(base64url.decode(challenge), (c) =>
              c.charCodeAt(0),
            ),
            allowCredentials: [
              {
                id: Uint8Array.from(base64url.decode(credentialId), (c) =>
                  c.charCodeAt(0),
                ),
                type: 'public-key',
                transports: ['internal'], // ['usb', 'ble', 'nfc'],
              },
            ],
            timeout: 15000,
            authenticatorSelection: {
              userVerification: 'preferred',
              authenticatorAttachment: 'platform', // cross-platform or platform (TPM)
            },
            attestation: 'direct',
          },
        })
        if (res) {
          json = await res.json()
          setStatusText('TouchID found')
          console.log('TouchID found:/n', JSON.stringify(json, null, 2))
        }
      } catch (err) {
        console.log('Failed to get TouchID authentication failed')
        console.error('ERROR:', err)
      }
    }
    return json
  }

  const handleClickShowFingerprint = async () => {
    setValues({ ...values, showFingerprint: !values.showFingerprint })
    let credentials = localStorage.getItem(
      'storybook-events-fingerprint-credentials',
    )
    if (credentials) {
      // localStorage.removeItem("storybook-events-fingerprint-credentials");
    }
    try {
      const credentialId = await registerFingerprint(
        values.userId,
        values.email,
        values.username,
      )
      localStorage.setItem(
        'storybook-events-fingerprint-credentials',
        JSON.stringify({ credentialId }),
      )
      console.log('Fingerprint registration successful')
    } catch (e) {
      console.error('Fingerprint registration failed', e)
    }
  }

  const handleMouseDownFingerprint = (event) => {
    event.preventDefault()
  }

  const handleHoverShowPassword = (value) => (event) => {
    if (event.type === 'keydown' && event.keyCode !== 32) return
    if (event.type === 'keyup' && event.keyCode !== 32) return
    let isMobile = window.matchMedia(
      'only screen and (max-width: 760px)',
    ).matches
    if (!isMobile) setValues({ ...values, showPassword: value })
  }

  const handleClickShowPassword = async () => {
    setValues({ ...values, showPassword: !values.showPassword })
    if (values.username === '') {
      setValues({ ...values, passwordHint: 'Type username first' })
      return
    }
    // Lookup password hint if username entered
    const response = await fetchWithTimeout('/api/v1/user/password-hint', {
      method: 'POST',
      body: JSON.stringify({ username: values.username }),
    })
    if (response && response.status === 200) {
      const json = await response.json()
      let passwordHint = 'No password hint found'
      if (json.passwordHint)
        passwordHint = 'Password hint: ' + json.passwordHint
      setValues({ ...values, passwordHint })
    }
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleLogin = async (event) => {
    if (window.PublicKeyCredential) {
      // await getFingerprint(values.credentialId, values.challenge);
    }

    // POST request to backend
    const res = await fetchWithTimeout('/api/v1/user/signin', {
      method: 'POST',
      body: JSON.stringify({
        username: values.username,
        password: values.password,
      }),
    })
    if (res && res.status === 200) {
      const json = await res.json()
      console.log('Login response', JSON.stringify(json, null, 2))

      // Save user profile in context and local storage on login
      await context.onLogin(json)

      // Redirect to request private URL or home page
      // React router removed props.location.state
      let redirectURL = '/'
      if (props.location.state && props.location.state.from) {
        console.log('History location', JSON.stringify(props.location, null, 2))
        redirectURL = props.location.state.from
      }

      document.location.href = redirectURL
    }
    event.preventDefault()
    return true
  }

  const handleResetPassword = (event) => {
    document.location.href = '/reset-password'
    event.preventDefault()
  }

  const handleCreateAccount = (event) => {
    document.location.href = '/signup'
    event.preventDefault()
  }

  return (
    <div className={classes.root}>
      <form id="login" className={classes.form} noValidate autoComplete="off">
        <div className={classes.box}>
          <h2 className={classes.header}>Login</h2>
          <div style={{ display: statusText ? 'block' : 'none' }}>
            {statusText}
          </div>
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField,
            )}
          >
            <InputLabel htmlFor="username">E-mail</InputLabel>
            <Input
              id="username"
              value={values.username}
              onChange={handleChange('username')}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && passwordElement) {
                  event.preventDefault()
                  passwordElement.current.focus()
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowFingerprint}
                    onMouseDown={handleMouseDownFingerprint}
                  >
                    {values.showFingerprint ? <Fingerprint /> : <Keyboard />}
                  </IconButton>
                </InputAdornment>
              }
              aria-describedby="username-helper-text"
              inputProps={{
                'aria-label': 'username',
              }}
            />
            <FormHelperText id="username-helper-text">
              {values.usernameHint}
            </FormHelperText>
          </FormControl>

          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField,
            )}
            style={{ display: values.showFingerprint ? 'inline-flex' : 'none' }}
          >
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              inputRef={passwordElement}
              id="password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  handleLogin(event)
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseOver={handleHoverShowPassword(true)}
                    onMouseOut={handleHoverShowPassword(false)}
                    onKeyDown={handleHoverShowPassword(true)}
                    onKeyUp={handleHoverShowPassword(false)}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              aria-describedby="password-helper-text"
              inputProps={{
                'aria-label': 'password',
              }}
            />
            <FormHelperText id="password-helper-text">
              {values.passwordHint}
            </FormHelperText>
          </FormControl>

          <div className={classes.actions}>
            <Button
              variant="contained"
              onClick={handleLogin}
              color="primary"
              className={classes.button}
              endIcon={<MenuBookIcon />}
            >
              Login
            </Button>

            <Button
              variant="contained"
              onClick={handleCreateAccount}
              color="secondary"
              className={classes.button}
            >
              Signup
            </Button>

            <br />
            <Link
              style={{ marginLeft: 8 }}
              component="button"
              variant="body2"
              onClick={handleResetPassword}
            >
              Reset password
            </Link>
          </div>
        </div>
      </form>
    </div>
  )
}

export default withRouter(withContext(Login))
