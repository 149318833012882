import React from 'react'
// We use Helmet async for function components, but helmet for traditional React components
import { Helmet, HelmetProvider } from 'react-helmet-async'

const MessageMetaData = (props) => {
  let { message } = props

  return (
    <HelmetProvider>
      {message && message.MetaData && (
        <Helmet>
          <meta charSet="utf-8" />
          {/* Standard metadata tags */}
          <title>{message.MetaData.title ? message.MetaData.title : ''}</title>
          <meta
            name="description"
            content={
              message.MetaData.description ? message.MetaData.description : ''
            }
          />
          {/* End standard metadata tags */}
          {/* Facebook tags */}
          <meta
            property="og:type"
            content={message.MetaData.ogType ? message.MetaData.ogType : ''}
          />
          <meta
            property="og:title"
            content={message.MetaData.ogTitle ? message.MetaData.ogTitle : ''}
          />
          <meta
            property="og:description"
            content={
              message.MetaData.ogDescription
                ? message.MetaData.ogDescription
                : ''
            }
          />
          {/* End Facebook tags */}
          {/* Twitter tags */}
          <meta
            name="twitter:creator"
            content={message.MetaData.author ? message.MetaData.author : ''}
          />
          <meta
            name="twitter:card"
            content={message.MetaData.ogType ? message.MetaData.ogType : ''}
          />
          <meta
            name="twitter:title"
            content={message.MetaData.ogTitle ? message.MetaData.ogTitle : ''}
          />
          <meta
            name="twitter:description"
            content={
              message.MetaData.ogDescription
                ? message.MetaData.ogDescription
                : ''
            }
          />
          {/* End Twitter tags */}
        </Helmet>
      )}
    </HelmetProvider>
  )
}

export default MessageMetaData
