// MessagePlaceholder looks for changes to messageList.length to trigger refresh

import React, { useState, useEffect } from 'react'
import parseHTML from 'html-react-parser'
import { getUser } from '../../utils'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@material-ui/core/IconButton'

// Pass callback to parent context setMessageList
const MessagePlaceholder = (props) => {
  let { list, name, type, state, property, value } = props

  if (!list) throw new Error('MessagePlaceholder is missing list property')
  if (!name) throw new Error('MessagePlaceholder is missing name property')
  type = type ? type.toLowercase() : 'html'
  state = state ? state.toLowercase() : 'final'
  property = property ? property : 'html'
  value = value ? value : '' // Default value

  const user = getUser()
  const isSuperuser = user && ['superuser', 'admin'].indexOf(user.role) >= 0
  const [html, setHTML] = useState('')
  const [message, setMessage] = useState(null)

  const handleEdit = (event) => {
    if (message) {
      window.open('/message/' + message.id + '/edit', '_blank')
    }
  }

  // ComponentDidMount
  useEffect(() => {
    // eslint-disable-next-line
    let messageList = list.filter((message) => {
      if (message === null) return false
      return (
        message.name === name &&
        message.type === type &&
        message.state === state
      )
    })
    // console.log('List',list);
    // console.log('name='+name+', type='+type+', state='+state);
    // console.log('Message',messageList);
    if (messageList.length > 0) {
      let message = messageList[0]
      setMessage(message)
      let html = message[property] ? parseHTML(message[property]) : value
      setHTML(html)
    }
    // Ignore missing dependencies only trigger useEffect, when list size changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.length]) // Watch for changes of list length, as arrays are a reference

  return (
    <>
      {isSuperuser && (
        <IconButton
          size="small"
          style={{ width: 28, height: 28, background: '#FF0000', margin: 4 }}
          component="span"
          onClick={handleEdit}
        >
          <EditIcon />
        </IconButton>
      )}
      {html}
    </>
  )
}

export default MessagePlaceholder
