import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
/*
import { 
   format, 
   addDays, 
   startOfMonth, 
   endOfMonth, 
   startOfWeek, 
   endOfWeek, 
   isSameDay, 
   isSameMonth,
   addMonths, 
   subMonths 
} from "date-fns";
*/
import { getUser, getSession, fetchWithTimeout } from '../../utils'
import './event-calendar.css'
import { DateTime } from 'luxon'

// import IconButton from '@material-ui/core/IconButton';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { EventCalendarList } from '../event-calendar-list/EventCalendarList'

/*
const partOfDay = {
   Morning: {
      start: 5, // am = after midnight
      end: 12  // pm=past midday (noon)
   },     
   EarlyMorning: {
      start: 5,
      end: 8
   },
   LateMorning: {
      start: 11,
      end: 12
   },
   Afternoon: {
      start: 12,  // after 
      end: 17
   },
   EarlyAfternoon: {
      start: 13,
      end: 15,
   },
   LateAfternoon: {
      start: 16,
      end: 17,
   },
   Evening: {
      start: 17,
      end: 21
   },    
   EarlyEvening: {
      start: 17,
      end: 19
   },   
   Night: {
      start: 21,
      end: 4
   }    
}
*/

const EventCalendar = (props) => {
  // eslint-disable-next-line
  const user = getUser()
  // eslint-disable-next-line
  const session = getSession()

  let { isoDate } = useParams()
  let startdate = DateTime.now()
  let startview = 'calendar'
  if (isoDate) {
    try {
      startdate = DateTime.fromISO(isoDate)
      startview = 'dayview'
    } catch (err) {}
  }

  const [currentDate, setCurrentDate] = useState(startdate)
  const [selectedDate, setSelectedDate] = useState(startdate)

  // eslint-disable-next-line
  const [eventList, setEventList] = useState([])
  const [eventIndex, setEventIndex] = useState({}) // index based on start date
  const [view, setView] = useState(startview)

  const getPublicEvents = useCallback(async () => {
    console.log('Get public events', currentDate.toISODate())
    let startdate = currentDate.startOf('month').toISODate()
    let enddate = currentDate.endOf('month').toISODate()
    let res = await fetchWithTimeout(
      '/api/v1/event?startdate=' + startdate + '&enddate=' + enddate,
      {},
      'json',
    ) // return response as json
    if (res) {
      let index = {}
      for (let i in res.eventList) {
        let event = res.eventList[i]
        if (!index[event.startdate]) index[event.startdate] = []
        index[event.startdate].push(i) // store index to event in event list
      }
      setEventList(res.eventList)
      setEventIndex(index)
    }
  }, [currentDate])

  useEffect(() => {
    getPublicEvents()
  }, [getPublicEvents])

  const header = () => {
    const dateFormat = 'MMMM yyyy'
    return (
      <div className="header row flex-middle">
        <div className="column col-start">
          <div className="icon" onClick={prevMonth}>
            chevron_left
          </div>
        </div>
        <div className="column col-center">
          <span>{currentDate.toFormat(dateFormat)}</span>
        </div>
        <div className="column col-end">
          <div className="icon" onClick={nextMonth}>
            chevron_right
          </div>
        </div>
      </div>
    )
  }

  const days = () => {
    const days = []
    let startDate = currentDate.startOf('week')

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="column col-center" key={i}>
          {startDate.plus({ days: i }).toLocaleString({ weekday: 'short' })}
        </div>,
      )
    }
    return <div className="days row">{days}</div>
  }

  const cells = () => {
    const today = DateTime.now()
    const monthStart = currentDate.startOf('month')
    const monthEnd = monthStart.endOf('month')
    const startDate = monthStart.startOf('week')
    const endDate = monthEnd.endOf('week')
    const dateFormat = 'd'
    const rows = []
    let days = []
    let day = startDate
    let formattedDate = ''

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        let dayIndex = day.toISODate()
        let dayEvents = eventIndex[dayIndex] ? eventIndex[dayIndex] : []
        formattedDate = day.toFormat(dateFormat)
        const cloneDay = day
        days.push(
          <div
            className={`column cell ${
              day.month !== monthStart.month
                ? 'disabled'
                : day.day === selectedDate.day
                ? 'selected'
                : day.day === today.day &&
                  day.month === today.month &&
                  day.year === today.year
                ? 'today'
                : ''
            }`}
            key={day}
            onClick={() => onDateClick(cloneDay)}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
            <ul className="event-list">
              {dayEvents.map((index) => {
                if (!eventList || !eventList[index]) return null
                let event = eventList[index]
                return (
                  <li key={'event-' + event.id}>
                    <span className="event-list-item">{event.title}</span>
                  </li>
                )
              })}
            </ul>
          </div>,
        )
        day = day.plus({ days: 1 })
      }

      rows.push(
        <div className="row" key={day}>
          {' '}
          {days}{' '}
        </div>,
      )
      days = []
    }
    return <div className="body">{rows}</div>
  }

  const nextMonth = () => {
    let next = DateTime.fromISO(currentDate.plus({ months: 1 }).toISO())
    // getPublicEvents uses callback monitoring currentData changes and witll be called automatically
    // Each set method called on a useState variable will trigger a render of the return statement in this function
    setCurrentDate(next)
  }

  const prevMonth = () => {
    let prev = DateTime.fromISO(currentDate.minus({ months: 1 }).toISO())
    setCurrentDate(prev)
  }

  const onDateClick = (day) => {
    console.log('Clicked', day.toISODate())
    setSelectedDate(day)
    setView('dayview')
  }

  const renderCalendar = () => {
    if (view !== 'calendar') return null
    return (
      <div className="calendar">
        <div>{header()}</div>
        <div>{days()}</div>
        <div>{cells()}</div>
      </div>
    )
  }

  const showCalendar = (day) => {
    if (day) setSelectedDate(day)
    setView('calendar')
  }

  const addEvent = (event) => {
    console.log('Add event')
    document.location.href =
      '/event/0/edit?startdate=' + selectedDate.toISODate()
  }

  const showEvent = (eventId) => (event) => {
    console.log('Show event', eventId)
    document.location.href = '/event/' + eventId + '/view'
  }

  const dayHeader = () => {
    const dateFormat = 'dd-MM-yyyy'
    return (
      <div className="dayview-header row flex-middle">
        <div className="column col-start" style={{ width: 40 }}>
          <div className="icon" onClick={showCalendar}>
            calendar_today
          </div>
        </div>
        <div className="column col-center" style={{ minWidth: 200 }}>
          <div>{'' + selectedDate.toFormat(dateFormat)}</div>
        </div>
        <div className="column col-end" style={{ width: 40 }}>
          <div className="icon" onClick={addEvent}>
            add
          </div>
        </div>
      </div>
    )
  }

  const renderDayView = () => {
    if (view !== 'dayview') return null
    let dayIndex = selectedDate.toISODate()
    let dayEvents = eventIndex[dayIndex] ? eventIndex[dayIndex] : []
    return (
      <div className="dayview">
        <div>{dayHeader()}</div>
        <div>
          <ul className="dayview-event-list">
            {dayEvents.map((index) => {
              if (!eventList || !eventList[index]) return null
              let event = eventList[index]
              return (
                <li
                  key={'event-' + event.id}
                  className="dayview-event-list-item"
                  onClick={showEvent(event.id)}
                >
                  <EventCalendarList
                    startTime={
                      event.starttime ? event.starttime.substring(0, 5) : ''
                    }
                    endTime={event.endtime ? event.endtime.substring(0, 5) : ''}
                    title={event.title ? event.title : ''}
                    state={event.state ? event.state : ''}
                  />

                  {
                    // Code before list design implementation
                    /* 
                  <div className="icon">event</div>
                  <span style={{ marginLeft: 10 }}>
                    {event.starttime ? event.starttime.substring(0, 5) : ''}-
                    {event.endtime ? event.endtime.substring(0, 5) : ''}
                  </span>
                  <span style={{ marginLeft: 10, marginRight: 10 }}>
                    {event.title ? event.title : ''}
                  </span>
                  <br />
                  <span style={{ fontSize: '70%' }}>
                    {event.state ? event.state : ''}
                  </span>
                  */
                  }
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }

  return (
    <>
      {renderDayView()}
      {renderCalendar()}
    </>
  )
}
export default EventCalendar
