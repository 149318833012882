import React, { useRef, useEffect, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
//import MailIcon from '@material-ui/icons/Mail';
//import SmsIcon from '@material-ui/icons/Sms';
//import ChatIcon from '@material-ui/icons/Chat';
import StarRateIcon from '@material-ui/icons/StarRate'
import StarOutlineIcon from '@material-ui/icons/StarOutline'
import VideocamIcon from '@material-ui/icons/Videocam'
import Button from '@material-ui/core/Button'

import List from '@material-ui/core/List'
//import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
// import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person'
import CallIcon from '@material-ui/icons/Call'
import MailIcon from '@material-ui/icons/Mail'
import AddShoppingCart from '@material-ui/icons/AddShoppingCart'
import RemoveShoppingCart from '@material-ui/icons/RemoveShoppingCart'
import PaymentIcon from '@material-ui/icons/Payment'

import '../App.css'
import {
  getUser,
  getSession,
  getOrder,
  addOrderLine,
  delOrderLine,
  fetchWithTimeout,
} from '../utils'
import { DateTime } from 'luxon'
import { Context } from '../context/Context'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  form: {},
  header: {
    width: '100%',
    textAlign: 'center',
  },
  box: {
    margin: 5,
    padding: 5,
    border: '1px solid #DDDDDD',
    borderRadius: 20,
    boxShadow: '5px 5px #EEEEEE',
    width: 300,
    backgroundColor: '#FFFFFF',
  },
  actions: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  label: {
    display: 'inline-flex',
    width: 60,
  },
  textField: {
    margin: 5,
    flex: 1,
  },
  button: {
    margin: 4,
    width: 100,
    borderRadius: 20,
  },
  anchor: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  image: {
    width: '100%',
  },
  blogPostList: {
    margin: 0,
    padding: 0,
  },
  blogPostListItem: {
    width: '100%',
    border: '1px solid #DDDDDD',
    borderRadius: 5,
    padding: 5,
  },
  blogPostListItemLabel: {
    display: 'inline-block',
    width: 50,
  },
}))

function EventView(props) {
  // eslint-disable-next-line
  const context = useContext(Context)

  // Read locale translations from react-intl
  const intl = useIntl()
  const t = (id, defaultMessage) =>
    intl.formatMessage({
      id,
      defaultMessage: defaultMessage ? defaultMessage : id,
    })

  const formEl = useRef(null)
  const eventEl = useRef(null)

  let { eventId, startdate } = useParams()

  const classes = useStyles()

  // Event form values
  const [values, setValues] = React.useState({
    id: 0,
    userId: 0,
    startdate: startdate
      ? DateTime.fromISO(startdate).toFormat('dd-MM-yyyy')
      : '',
    enddate: '',
    starttime: '',
    endtime: '',
    repeatSeqId: '',
    repeatdays: '',
    title: '',
    imageURL: '',
    type: '',
    state: '',
    domain: '',
    topic: '',
    gpsLocation: '',
    gpsLocationMessage: '',
    // Host user information
    firstname: '',
    surname: '',
    birthday: '',
    gender: '',
    email: '',
    phone: '',
    avatarURL: '',
  })

  // Event values before they where updated in form values
  // eslint-disable-next-line

  // eslint-disable-next-line
  const [inShoppingCart, setInShoppingCart] = React.useState(
    getOrder().orderLine.findIndex((item) => item.eventId === eventId) >= 0,
  )

  // eslint-disable-next-line
  const [event, setEvent] = React.useState(null)
  // eslint-disable-next-line
  const [mailText, setMailText] = React.useState('')
  // eslint-disable-next-line
  const [smsText, setSmsText] = React.useState('')
  // Event agenda form values
  // eslint-disable-next-line
  const [agendaValues, setAgendaValues] = React.useState({
    id: 0,
  })

  const [eventAgenda, setEventAgenda] = React.useState([])

  // eslint-disable-next-line
  const [blogPostsValues, setBlogPostsValues] = React.useState({
    id: 0,
  })

  // eslint-disable-next-line
  const [eventBlogPosts, setEventBlogPosts] = React.useState([])

  // eslint-disable-next-line
  const [participantsValues, setParticipantsValues] = React.useState({
    id: 0,
  })

  // eslint-disable-next-line
  const [eventParticipants, setEventParticipants] = React.useState([])

  // eslint-disable-next-line
  const user = getUser()
  const session = getSession()

  // eslint-disable-next-line
  const [blogPostHTML, setBlogPostHTML] = React.useState('')
  // eslint-disable-next-line
  const [agendaHTML, setAgendaHTML] = React.useState('')

  const getEventInfo = useCallback(async (eventId) => {
    if (!eventId) return
    let res = await fetchWithTimeout('/api/v1/event/' + eventId, {
      headers: {
        Authorization: 'Bearer ' + session.token,
      },
    })
    if (res && res.status === 200) {
      let json = await res.json()
      // Store event values before we start editing them
      setEvent(json.event)
      // Store in form values and make sure null values are converted to default values like empty string or zero
      setValues({
        ...values,
        id: json.event.id ? json.event.id : 0,
        userId: json.event.userId ? json.event.userId : 0,
        startdate: json.event.startdate
          ? DateTime.fromISO(json.event.startdate).toFormat('dd-MM-yyyy')
          : '',
        enddate: json.event.enddate
          ? DateTime.fromISO(json.event.enddate).toFormat('dd-MM-yyyy')
          : '',
        starttime: json.event.starttime
          ? json.event.starttime.substring(0, 5)
          : '',
        endtime: json.event.endtime ? json.event.endtime.substring(0, 5) : '',
        repeatSeqId: json.event.repeatSeqId ? json.event.repeatSeqId : '',
        repeatdays: json.event.repeatdays ? json.event.repeatdays : 0,
        title: json.event.title ? json.event.title : '',
        imageURL: json.event.imageURL ? json.event.imageURL : '',
        type: json.event.type ? json.event.type : '',
        state: json.event.state ? json.event.state : '',
        domain: json.event.domain ? json.event.domain : '',
        topic: json.event.topic ? json.event.topic : '',
        gpsLocation: json.event.gpsLocation ? json.event.gpsLocation : '',
        firstname:
          json.event.User && json.event.User.firstname
            ? json.event.User.firstname
            : '',
        surname:
          json.event.User && json.event.User.surname
            ? json.event.User.surname
            : '',
        birthday:
          json.event.User && json.event.User.birthday
            ? json.event.User.birthday
            : '',
        gender:
          json.event.User && json.event.User.gender
            ? json.event.User.gender
            : '',
        email:
          json.event.User && json.event.User.email ? json.event.User.email : '',
        phone:
          json.event.User && json.event.User.phone ? json.event.User.phone : '',
        avatarURL:
          json.event.User && json.event.User.avatarURL
            ? json.event.User.avatarURL
            : '',
      })
      // console.log('Event',JSON.stringify(json.event,null,2));
    }
    // eslint-disable-next-line
  }, [])

  const getEventAgenda = useCallback(async (eventId) => {
    if (!eventId) return
    let res = await fetchWithTimeout('/api/v1/event/' + eventId + '/agenda', {
      headers: {
        Authorization: 'Bearer ' + session.token,
      },
    })
    if (res && res.status === 200) {
      let json = await res.json()
      // Store event values before we start editing them
      setEventAgenda(json.agenda)
      // Store in form values and make sure null values are converted to default values like empty string or zero
      if (json.agenda.length > 0) {
        setAgendaValues(json.agenda[0])
      }
      // console.log('EventAgenda', JSON.stringify(json.agenda,null,2));
    }
    // eslint-disable-next-line
  }, [])

  const getEventBlogPosts = useCallback(async (eventId) => {
    if (!eventId) return
    let res = await fetchWithTimeout('/api/v1/event/' + eventId + '/blogpost', {
      headers: {
        Authorization: 'Bearer ' + session.token,
      },
    })
    if (res && res.status === 200) {
      let json = await res.json()
      // Store event values before we start editing them
      setEventBlogPosts(json.blogPostList)
      // Store in form values and make sure null values are converted to default values like empty string or zero
      if (json.blogPostList.length > 0) {
        setBlogPostsValues(json.blogPostList[0])
      }
      // console.log('EventBlogPosts', JSON.stringify(json.blogPosts,null,2));
    }
    // eslint-disable-next-line
  }, [])

  const getEventParticipants = useCallback(async (eventId) => {
    if (!eventId) return
    let res = await fetchWithTimeout(
      '/api/v1/event/' + eventId + '/participant',
      {
        headers: {
          Authorization: 'Bearer ' + session.token,
        },
      },
    )
    if (res && res.status === 200) {
      let json = await res.json()
      // Store participants before we start editing them
      setEventParticipants(json.participantList)
      // Store in form values and make sure null values are converted to default values like empty string or zero
      if (json.participantList.length > 0) {
        setParticipantsValues(json.participantList[0])
      }
      // console.log('EventBlogPosts', JSON.stringify(json.blogPosts,null,2));
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (eventId) {
      getEventInfo(eventId)
      getEventAgenda(eventId)
      getEventBlogPosts(eventId)
      getEventParticipants(eventId)
    }
  }, [
    getEventInfo,
    getEventAgenda,
    getEventBlogPosts,
    getEventParticipants,
    eventId,
  ])

  const handleEditEvent = () => {
    console.log('Edit event', values.id)
    document.location.href = '/event/' + values.id + '/edit'
  }

  const handleAddShoppingCart = () => {
    let order = getOrder()
    let orderLineIndex = order.orderLines.findIndex(
      (item) => item.eventId === eventId,
    )
    if (orderLineIndex < 0) {
      addOrderLine({ eventId })
      setInShoppingCart(true)
    }
  }

  const handleRemoveShoppingCart = () => {
    let order = getOrder()
    let orderLineIndex = order.orderLines.findIndex(
      (item) => item.eventId === eventId,
    )
    if (orderLineIndex >= 0) {
      delOrderLine(orderLineIndex)
      setInShoppingCart(false)
    }
  }

  const handleCheckout = () => {
    let order = getOrder()
    if (order.orderLines.length > 0)
      document.location.href = '/payment/checkout'
  }

  const handleSendMail = (email) => {
    document.location.href = 'mailto:' + email
  }

  const handlePhoneCall = (phone) => {
    document.location.href = 'callto:' + phone
  }

  const handleJoinVideoConference = () => {
    document.location.href = '/event/' + eventId + '/meeting'
  }

  const handleInviteToJoinVideoConference = () => {}

  const agendaList = () => (
    <div className={classes.agendaList}>
      <List>
        {eventAgenda.map((agendaItem, index) => {
          return (
            <ListItem button key={'agenda-item-' + index}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              {agendaItem.title}
            </ListItem>
          )
        })}
      </List>
    </div>
  )

  const participantList = () => (
    <div className={classes.participantList}>
      <List>
        {eventParticipants.map((participantItem, index) => {
          return (
            <ListItem button key={'participant-item-' + index}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              {participantItem.User.firstname} {participantItem.User.surname}
              <br />
              {participantItem.User.email}
            </ListItem>
          )
        })}
      </List>
    </div>
  )

  /*
    {
      "id": 2,
      "eventId": 14,
      "userId": 2,
      "title": "What an event",
      "description": null,
      "state": "published",
      "rating": 4,
      "createdAt": null,
      "updatedAt": null,
      "Event": {
        "userId": 1,
        "state": "published"
      },
      "User": {
        "id": 2,
        "firstname": "Nikolaj",
        "surname": "Lisberg Hansen"
      }
    },
  */

  const blogPostList = () => (
    <div>
      <List className={classes.blogPostList}>
        {eventBlogPosts.map((blogPostItem, index) => {
          return (
            <ListItem
              button
              disableGutters={true}
              key={'blogposts-item-' + index}
            >
              <div className={classes.blogPostListItem}>
                <div style={{ fontWeight: 'bold' }}>
                  <span style={{ marginLeft: 10 }}>
                    {blogPostItem.title ? blogPostItem.title : ''}
                  </span>
                </div>
                <div>
                  {['1', '2', '3', '4', '5'].map((rate, index) => {
                    return index < blogPostItem.rating ? (
                      <StarRateIcon key={'rating-' + index} />
                    ) : (
                      <StarOutlineIcon
                        style={{ color: '#DDDDDD' }}
                        key={'rating-' + index}
                      />
                    )
                  })}
                </div>
                <div>
                  {blogPostItem.description ? blogPostItem.description : ''}
                </div>
                <div
                  style={{
                    fontSize: '70%',
                    marginTop: 10,
                    borderTop: '1px solid #DDDDDD',
                  }}
                >
                  <span className={classes.blogPostListItemLabel}>Created</span>{' '}
                  {blogPostItem.createdAt
                    ? DateTime.fromISO(blogPostItem.createdAt).toFormat(
                        'dd-MM-yyyy hh:mm',
                      )
                    : ''}
                  <br />
                  <span className={classes.blogPostListItemLabel}>By</span>{' '}
                  {blogPostItem.User ? blogPostItem.User.username : ''}
                </div>
              </div>
            </ListItem>
          )
        })}
      </List>
    </div>
  )

  return (
    <div className={classes.root}>
      {user && user.id === values.userId && (
        <div
          className={classes.actions}
          style={{ width: '100%', marginRight: 10 }}
        >
          <Button
            variant="outlined"
            color="inherit"
            className={classes.button}
            onClick={handleEditEvent}
          >
            {t('event.edit', 'Edit')}
          </Button>
        </div>
      )}

      <form
        ref={formEl}
        id="event"
        className={classes.form}
        noValidate
        autoComplete="off"
      >
        <FormGroup className={classes.box} style={{ paddingBottom: 50 }}>
          <h2 ref={eventEl} className={classes.header}>
            <IconButton
              aria-label="add shopping cart"
              style={{
                float: 'left',
                display: inShoppingCart ? 'none' : 'inline-flex',
              }}
              onClick={(event) => handleAddShoppingCart()}
            >
              <AddShoppingCart />
            </IconButton>
            <IconButton
              aria-label="remove shopping cart"
              style={{
                float: 'left',
                display: inShoppingCart ? 'inline-flex' : 'none',
              }}
              onClick={(event) => handleRemoveShoppingCart()}
            >
              <RemoveShoppingCart />
            </IconButton>
            Event
            <IconButton
              style={{ float: 'right' }}
              aria-label="checkout shopping cart"
              onClick={(event) => handleCheckout()}
            >
              <PaymentIcon />
            </IconButton>
            <br />
            {values.title}
          </h2>
          <div>
            <Avatar
              style={{ float: 'left' }}
              alt="Host profile image"
              src={values.avatarURL}
            >
              <IconButton
                style={{ borderRadius: '50%' }}
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`Hosted by ${values.firstname} ${values.surname}`}
              >
                <PersonIcon />
              </IconButton>
            </Avatar>

            {values.firstname}
            {values.surname}
            <IconButton
              aria-label="send email"
              style={{
                width: 48,
                top: -16,
                float: 'right',
                display: values.email !== '' ? 'inline-flex' : 'none',
              }}
              onClick={(event) => handleSendMail(values.email)}
            >
              <MailIcon />
            </IconButton>
            <IconButton
              aria-label="phone call"
              style={{
                width: 48,
                top: -16,
                float: 'right',
                display: values.phone !== '' ? 'inline-flex' : 'none',
              }}
              onClick={(event) => handlePhoneCall(values.phone)}
            >
              <CallIcon />
            </IconButton>
          </div>

          <img
            alt={values.title}
            src={values.imageURL}
            className={classes.image}
          />

          <table>
            <tbody>
              <tr>
                <td>Date</td>
                <td>{values.startdate}</td>
              </tr>
              <tr>
                <td>Time</td>
                <td>
                  {values.starttime}-{values.endtime}
                </td>
              </tr>
              <tr>
                <td>Topic</td>
                <td>{values.topic}</td>
              </tr>
            </tbody>
          </table>
        </FormGroup>
      </form>

      <form
        id="eventagenda"
        className={classes.form}
        noValidate
        autoComplete="off"
      >
        <FormGroup className={classes.box} style={{ paddingBottom: 50 }}>
          <h2 className={classes.header}>
            Agenda
            <IconButton
              style={{ float: 'right', borderRadius: '50%' }}
              sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
              aria-label={`Join video conference`}
              onClick={(event) => handleJoinVideoConference()}
            >
              <VideocamIcon />
            </IconButton>
          </h2>

          {agendaList()}
        </FormGroup>
      </form>

      <form
        id="eventparticipants"
        className={classes.form}
        noValidate
        autoComplete="off"
      >
        <FormGroup className={classes.box} style={{ paddingBottom: 50 }}>
          <h2 className={classes.header}>
            Participants
            <IconButton
              style={{ float: 'right', borderRadius: '50%' }}
              sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
              aria-label={`Join video conference`}
              onClick={(event) => handleInviteToJoinVideoConference()}
            >
              <VideocamIcon />
            </IconButton>
          </h2>

          {participantList()}
        </FormGroup>
      </form>

      <form
        id="eventblogposts"
        className={classes.form}
        noValidate
        autoComplete="off"
      >
        <FormGroup className={classes.box} style={{ paddingBottom: 50 }}>
          <h2 className={classes.header}>Blog posts</h2>

          {blogPostList()}
        </FormGroup>
      </form>
    </div>
  )
}

export default EventView
