import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import { styled } from '@mui/system'

const StyledAvatar = styled(Avatar)({
  backgroundColor: '#15538F',
})

const StyledListItemText = styled(ListItemText)(({ theme, ...props }) => ({
  // To select primary text inside "ListItemText"
  '& .MuiListItemText-primary': {
    fontWeight: 500,
    fontSize: '1.25rem',
  },
  // To select secondary text inside "ListItemText"
  '& .MuiListItemText-secondary': {
    fontWeight: 400,
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))

export function EventCalendarList({ startTime, endTime, title, state }) {
  return (
    <>
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
        }}
      >
        <ListItem>
          <ListItemAvatar>
            <StyledAvatar>
              <EventIcon></EventIcon>
            </StyledAvatar>
            <Typography variant="body2">{state}</Typography>
          </ListItemAvatar>
          <ListItemText>
            <StyledListItemText
              primary={`${startTime} - ${endTime}`}
              secondary={title}
            />
          </ListItemText>
        </ListItem>
      </List>
    </>
  )
}
