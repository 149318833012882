// import logo from './logo.svg';
import './App.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
//import clsx from 'clsx';
import React, {
  Suspense,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
// https://reactrouter.com/web/guides/quick-start
import { Route, Switch, withRouter } from 'react-router-dom'
import PrivateRoute from './components/private-route'
import { IntlProvider } from 'react-intl'
import {
  // Cookies,
  CookiesProvider,
  withCookies,
} from 'react-cookie'
import { withContext } from './components/context-container'
import Meetup from './pages/Meetup'
import Navigation from './components/navigation'
import Grid from '@material-ui/core/Grid'
import Signup from './pages/Signup'
import Login from './pages/Login'
import ResetPassword from './pages/ResetPassword'
import ChangePassword from './pages/ChangePassword'
import Contact from './pages/Contact'
import FAQ from './pages/FAQ'
import { Context } from './context/Context'
import {
  getLocalStorageJson,
  setLocalStorageJson,
  getUser,
  getSession,
  hasValidSession,
  getTeaser,
  setTeaser,
  fetchWithTimeout,
  getLocale,
} from './utils'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import { green, pink } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen'
import ShareIcon from '@material-ui/icons/Share'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import PersonIcon from '@material-ui/icons/Person'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import ConfirmDialog from './components/confirm-dialog'
// In test set domain to test.denlillemusikskole.dk for navigation to work.
import manifestTemplate from './manifestTemplate.json'

// Language translations
import locales from './locales'

// Subscription teaser on frontpage uses Card
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'

import Button from '@material-ui/core/Button'
import { ErrorBoundary } from 'react-error-boundary'

// Public pages
import SearchEvent from './components/search-event'
import SearchProduct from './components/search-product'
import EventView from './pages/EventView'
import EventCalendar from './components/event-calendar'
// import Calendar from './components/calendar';
// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginReact from '@bugsnag/plugin-react'

import MessageList from './components/message-list'
// import { getMessage } from './components/message-list/MessageListHelper';
import MessagePlaceholder from './components/message-list/MessagePlaceholder'
import MessageMetaData from './components/message-list/MessageMetaData'

const isProd = document.location === 'https://storybook.events'
/*
Bugsnag.start({
  apiKey: '0a942893168b84c8d69365b7724faf44',
  plugins: [new BugsnagPluginReact()]
})
const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
*/

// Lazy load private pages!
const MyPage = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './pages/MyPage'),
)
const OpenStreetMap = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './components/open-street-map'),
)
const Event = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './pages/Event'),
)
const Whiteboard = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './components/whiteboard'),
)
const MeetingRoom = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './components/meeting-room'),
)
const Checkout = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './components/checkout'),
)
const PaymentCancel = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './pages/QuickPayCancel'),
)
const PaymentContinue = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './pages/QuickPayContinue'),
)
const Message = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './pages/Message'),
)
const QueryBuilder = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './pages/QueryBuilder'),
)
const QueryRunner = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './pages/QueryRunner'),
)
const Record = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './pages/Record'),
)
const Document = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './pages/Document'),
)
// QuickPay test form
const QuickPay = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './components/quickpay'),
)
const QuickPayCancel = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './pages/QuickPayCancel'),
)
const QuickPayContinue = React.lazy(() =>
  import(/* webpackChunkName: "private" */ './pages/QuickPayContinue'),
)

// Lazy load admin pages, because normal users dont need them at all.
// See https://reactjs.org/docs/code-splitting.html
const Dashboard = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/Dashboard'),
)
const OrderDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/OrderDataGrid'),
)
const UserDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/UserDataGrid'),
)
const MessageDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/MessageDataGrid'),
)
const NoteDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/NoteDataGrid'),
)
const TopicDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/TopicDataGrid'),
)

const CalendarDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/CalendarDataGrid'),
)

const ExceptionDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/ExceptionDataGrid'),
)

const QueryDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/QueryDataGrid'),
)
const PaymentTransactionDataGrid = React.lazy(() =>
  import(
    /* webpackChunkName: "admin" */ './pages/admin/PaymentTransactionDataGrid'
  ),
)
const LogDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/LogDataGrid'),
)
const CustomerDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/CustomerDataGrid'),
)
const ProductDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/ProductDataGrid'),
)
const UserGroupDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/UserGroupDataGrid'),
)
const ClassDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/ClassDataGrid'),
)
const EventDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/EventDataGrid'),
)

const EventPlannerDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/EventPlannerDataGrid'),
)

const EventParticipantDataGrid = React.lazy(() =>
  import(
    /* webpackChunkName: "admin" */ './pages/admin/EventParticipantDataGrid'
  ),
)

const CourseDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/CourseDataGrid'),
)
const RecordDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/RecordDataGrid'),
)
const DocumentDataGrid = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/DocumentDataGrid'),
)
// Admin tools
const SequelizeManager = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/SequelizeManager'),
)
const SolrAD = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ './pages/admin/SolrAD'),
)

// https://developer.mozilla.org/en-US/docs/Web/Manifest/scope
// https://developer.chrome.com/docs/extensions/mv2/manifest/name/
const createManifest = (domain, log) => {
  // make a cloned copy of javascript manifest object is done fastest with parse and stringify, then update properties on cloned object
  const manifest = JSON.parse(JSON.stringify(manifestTemplate))
  if (manifest.name.length > 45)
    console.log('Watning manifest name is more than 45 characters')
  if (manifest.short_name.length > 12)
    console.log('Watning manifest short_name is more than 12 characters')
  // Add current domain to start_url
  if (manifest.start_url === '/') manifest.start_url = 'https://' + domain
  else if (manifest.start_url.startsWith('/'))
    manifest.start_url = 'https://' + domain + manifest.start_url
  // Add current domain to scope
  if (manifest.scope === '/') manifest.scope = 'https://' + domain
  if (manifest.scope.startsWith('/'))
    manifest.scope = 'https://' + domain + manifest.scope
  manifest.icons.forEach((icon) => {
    if (icon.src.startsWith('/')) icon.src = 'https://' + domain + icon.src
  })

  // make blob with manifest json and write dynamic URL to link with manifest-placeholder
  const manifestData = JSON.stringify(manifest, null, 2)
  const blob = new Blob([manifestData], { type: 'application/json' })
  // Jest jsdom environment does not yet support createObjectURL
  if (!window.URL.createObjectURL) window.URL.createObjectURL = function () {}
  const manifestURL = URL.createObjectURL(blob)
  const manifestLink = document.querySelector('#manifest-placeholder')
  if (manifestLink) manifestLink.setAttribute('href', manifestURL)
  if (log) {
    console.log('Created manifest.json for domain ' + domain)
    console.log('Link: ', manifestURL)
    console.log(manifestData)
  }
  return manifest
}

let hostname = document.location.hostname
let manifest = createManifest(hostname, true)
if (!manifest) manifest = { name: 'Storybook events' }

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  appHeader: {
    textAlign: 'center',
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    fontSize: 24,
    padding: 0,
    paddingLeft: 5,
    paddingRight: 5,
    margin: 0,
    backgroundImage: "url('/images/instant-travel.png')",
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#155384',
    color: '#FEFEFE',
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
  avatar: {
    width: 48,
    height: 48,
    cursor: 'pointer',
  },
  card: {
    position: 'fixed',
    zIndex: 1000,
    marginTop: 388,
    marginLeft: -5,
    maxWidth: 320,
    boxShadow: '2px 2px #8899AA',
  },
  cardContent: {
    height: 284,
    maxHeight: 284,
    overflowY: 'scroll',
    textAlign: 'left',
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
}))

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: 0,
    /*    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`, */
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      /*      
      animation: '$ripple 1.2s infinite ease-in-out', 
      border: '1px solid currentColor',
      */
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge)

function App() {
  // eslint-disable-next-line
  const context = useContext(Context)

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)

  // Initialize confirm dialog
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmDialogTitle, setConfirmDialogTitle] = useState('')
  const [confirmDialogContent, setConfirmDialogContent] = useState('')
  // Use state has a feature that if a function is used for initial state the function is called and the
  // return value of the function call is used as the initial state, so we wrap our function in a function
  const noOperation = () => {}
  const [confirmDialogClose, setConfirmDialogClose] = useState(
    () => noOperation,
  )
  const [confirmDialogConfirm, setConfirmDialogConfirm] = useState(
    () => noOperation,
  )
  const [confirmDialogValue, setConfirmDialogValue] = useState('ok')

  //  let isModal = false;
  //  const { location } = document.location.href;

  const classes = useStyles()

  // eslint-disable-next-line
  const [locale, setLocale] = useState(
    context.locale ? context.locale : getLocale(),
  )
  // eslint-disable-next-line
  const [messages, setMessages] = useState(locales[locale])

  // Read teaser display status from local storage data
  let initTeaserAccepted = getTeaser()
  if (document.location.search.indexOf('?teaser=true') >= 0)
    initTeaserAccepted = true
  if (document.location.search.indexOf('?teaser=false') >= 0)
    initTeaserAccepted = false
  if (initTeaserAccepted === undefined) {
    // Set teaser in local storage data setting
    initTeaserAccepted = false
    setTeaser(initTeaserAccepted)
  }

  const [teaserAccepted, setTeaserAccepted] = useState(initTeaserAccepted)
  // eslint-disable-next-line
  const [badgeContent, setBadgeContent] = useState(0)

  // Get user and session from local storage
  const user = getUser()
  //console.log('App user',JSON.stringify(user,null,2));

  // eslint-disable-next-line
  const session = getSession()

  const [avatarURL, setAvatarURL] = useState(
    user && user.avatarURL ? user.avatarURL : '',
  )

  const [systemUpdateText, setSystemUpdateText] = useState(null)

  // Array used to hold HTML content messages
  // eslint-disable-next-line
  const [messageList, setMessageList] = React.useState([])

  // eslint-disable-next-line
  const [page, setPage] = React.useState(null)

  const setPageContent = (list) => {
    if (list) {
      setMessageList(list)
      let message = list.length > 0 ? list[0] : null
      // Check if any properties has changed not just url
      let newPageHash = JSON.stringify(message ? message : {})
      let oldPageHash = JSON.stringify(page ? page : {})
      if (newPageHash !== oldPageHash) {
        // console.log('setPageContent: message:', message)
        setPage(message)
      }
    }
  }

  /*
  // Delay event execution 1 seconds, because event can fire many times
  // while user is dragging window to be resized. Only last event will 
  // be executed when timeout expires, before being cleared
  const [scrollTimer, setScrollTimer] = useState(null);
  const handleScroll = (event) => {
    let doc = document.documentElement; 
    let scrollTop = doc.scrollTop;
    let scrollLeft = doc.scrollLeft;
    if (scrollTimer) clearTimeout(scrollTimer);
    // Store last scroll event only to avoid many updates while user drags scrollbar
    const timer = setTimeout(function(){ 
      doc.scrollTop = scrollTop;
      doc.scrollLeft = scrollLeft;
    },1000);
    setScrollTimer(timer);
    if (event.preventDefault) event.preventDefault();
    return false;  
  }
  */

  // If manifest.display === 'standalone' resize is called every time
  // the browser bottom navigation bar automatically hides itself in Safari
  // If manifest.display === 'fullscreen' this is not a problem
  // just dont call self.forceUpdate() or Safari on iOS will refresh on scroll
  const [resizeTimer, setResizeTimer] = useState(null)
  const handleResize = (event) => {
    let doc = document.documentElement
    let scrollTop = doc.scrollTop
    let scrollLeft = doc.scrollLeft
    if (resizeTimer) clearTimeout(resizeTimer)
    const timer = setTimeout(function () {
      doc.scrollTop = scrollTop
      doc.scrollLeft = scrollLeft
      setResizeTimer(null)
    }, 1000)
    setResizeTimer(timer)
    if (event.preventDefault) event.preventDefault()
    return false
  }

  // /file/system-update return 200 if some text is found and 204 no content otherwise
  const getSystemUpdateText = useCallback(async () => {
    let key = document.location.hostname + ':system-update'
    let res = await fetchWithTimeout('/api/v1/system-update')
    if (res.status === 200) {
      let body = await res.text()
      if (typeof Storage !== 'undefined') localStorage.setItem(key, 'true')
      return body
    }
    if (typeof Storage !== 'undefined') localStorage.setItem(key, 'false')
    return null
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (user && user.avatarURL !== avatarURL) {
      setAvatarURL(user.avatarURL)
    }

    async function fetchData() {
      let text = await getSystemUpdateText()
      setSystemUpdateText(text)
    }
    // Skip all repeating window resize events and only use last one
    if (resizeTimer === null) fetchData()
  }, [user, avatarURL, resizeTimer, getSystemUpdateText])

  // WebKit on iOS is used for both Safari and Chrome on iPhone.
  // WebKit will unload app, when any action causes the app to lose
  // focus, even if the screen saver kicks in. We need to store state
  // to be able to restore state, when app is reloaded again.
  const handleBeforeUnload = (event) => {
    let key = document.location.hostname + ':history'
    let data = getLocalStorageJson(key, {})
    // Store scroll position
    // let doc = document.documentElement;
    // data.scrollTop = doc.scrollTop;
    // data.scrollLeft = doc.scrollLeft;
    data.location = window.location.href
    setLocalStorageJson(key, data)
  }

  // https://chromium.googlesource.com/chromium/src.git/+/65d0e0f4d84abab407f942e81b3937821075ec0d/chrome/browser/banners/app_banner_manager.cc
  // https://whatwebcando.today/installation.html
  // https://pwa.rocks/

  // This is only called in production build, not in development, where service worker is not registered
  const handleBeforeInstallPrompt = (event) => {
    // Stash the event for later use
    window.lastbeforeinstallpromptevent = event
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    if (event.preventDefault) event.preventDefault()
    return false
  }

  /*
      See https://a2hs.glitch.me/ for different browsers web app requirements
      https://developers.google.com/web/fundamentals/app-install-banners/#testing-the-app-install-banner
      https://developers.google.com/web/updates/2018/06/a2hs-updates
      https://superpwa.com/doc/test-pwa-google-chrome/

      beforeinstallprompt will only be fired when some conditions are true :

      - The PWA must not already be installed
      - Meets a user engagement heuristic that the user has at least 30s interaction with your web app.
        (for testing see chrome://flags/#bypass-app-banner-engagement-checks)
      - Your web app must include a web app manifest.
      - Your web app must be served over a secure HTTPS connection (thrust certificate if needed for test)
      - Has registered a service worker with a fetch event handler.

      On Chrome, if you already have added the site to home screen, beforeinstallprompt
      is not going to fire. Make sure your site isn't already added to home screen, and
      clear Chrome's site data or verify on different Android phone as well

      chrome://flags/#bypass-app-banner-engagement-checks

      test if already showing in standalone mode
      window.matchMedia('(display-mode: standalone)').matches
  */

  const needsToSeeSharePrompt = () => {
    // Detects if device is on iOS
    const isIos = () => {
      // return ['iPhone', 'iPad', 'iPod'].includes(navigator.platform);
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test(userAgent)
    }
    // Detects if device is in standalone mode, meaning already added to home screen
    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      return true
    }
    return false
  }

  const showEmailPrompt = (email, subject, body) => {
    let link = document.createElement('A')
    link.id = new Date().getTime()
    link.style.visibility = 'hidden'
    link.onclick = function () {
      this.href = 'mailto:' + email + '?subject=' + subject + '&body=' + body
    }
    document.body.appendChild(link)
    document.getElementById(link.id).click()
  }

  const openConfirmDialog = (title, content, value, onClose, onConfirm) => {
    setConfirmDialogTitle(title)
    setConfirmDialogContent(content)
    setConfirmDialogValue(value)
    if (onClose) setConfirmDialogClose(onClose)
    if (onConfirm) setConfirmDialogConfirm(onConfirm)
    setConfirmDialogOpen(true)
  }

  const showSharePrompt = (title, text, url) => {
    if (!title) title = 'storybook.events'
    if (!text)
      text = 'Share Storybook events app or scroll down for Add to Home screen'
    if (!url) url = 'https://storybook.events/'
    // In production log user sharing
    if (isProd) {
      // Bugsnag.notify(new Error('User '+(user.email?user.email:'')+' shared '+url));
    }
    if (!navigator.share) {
      console.log(
        'Share prompt not available on this browser, try to open email to share link',
      )
      // For most actions that do not require user action like mailto link, the onclose call is enough
      const onClose = (value) => {
        setConfirmDialogOpen(false)
        // if (value==='ok') showEmailPrompt('',title,text);
      }
      const onConfirm = () => {
        setConfirmDialogOpen(false)
        showEmailPrompt('', title, text)
      }
      openConfirmDialog(
        'Open email client',
        'Share link to Storybook events app on email?',
        'ok',
        () => onClose,
        () => onConfirm,
      )
      return false
    }
    if (url && !url.startsWith('https://')) {
      console.log('Share only works for secure URLs')
      return false
    }
    navigator
      .share({ title, text, url })
      .then(() => console.log('Successfully shared ' + url))
      .catch((error) => console.log('Error sharing ' + url, error))
  }

  // Only show add to home screen when service worker is registered
  // if (window.navigator.serviceWorker && window.navigator.serviceWorker.controller !== null) show = true;
  let show =
    window.lastbeforeinstallpromptevent && window.navigator.serviceWorker
      ? true
      : false

  // eslint-disable-next-line
  const [showAddToHomeScreen, setShowAddToHomeScreen] = useState(show)
  const addToHomeScreen = () => {
    if (needsToSeeSharePrompt()) {
      showSharePrompt()
    } else {
      if (!window.lastbeforeinstallpromptevent) {
        console.log('Show share prompt')
        showSharePrompt(null, 'Share Storybook events app')
        return
      }
      let key = document.location.hostname + ':install-prompt'
      if (typeof Storage !== 'undefined') {
        if (localStorage.getItem(key) === 'dismissed') {
          // Handle that user actively dismissed this dialog one time ealier?
          // window.lastbeforeinstallpromptevent = null;
        }
      }
      if (window.lastbeforeinstallpromptevent) {
        window.lastbeforeinstallpromptevent.prompt()
        window.lastbeforeinstallpromptevent.userChoice.then(function (choice) {
          if (choice.outcome === 'accepted') {
            console.log('User accepted to install app to home screen')
          } else {
            console.log('User dismissed to install app to home screen')
          }
          if (typeof Storage !== 'undefined') {
            localStorage.setItem(key, choice.outcome)
          }
          window.lastbeforeinstallpromptevent = null
        })
      }
    }
  }

  const [isOnline, setIsOnline] = useState(null)
  const updateOnlineIndicator = (event) => {
    setIsOnline(navigator.onLine)
    if (isOnline) document.title = 'Online'
  }

  const updateOfflineIndicator = (event) => {
    setIsOnline(navigator.onLine)
    if (isOnline) document.title = 'Offline'
  }

  // Store context state within the 5 seconds limit, after user closes browser
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    return function cleanup() {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  })

  // Store Add to Home screen install prompt
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
    return function cleanup() {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt,
      )
      window.lastbeforeinstallpromptevent = null
    }
  })

  // Handle online status
  useEffect(() => {
    window.addEventListener('online', updateOnlineIndicator)
    window.addEventListener('offline', updateOfflineIndicator)
    return function cleanup() {
      window.removeEventListener('online', updateOnlineIndicator)
      window.removeEventListener('offline', updateOfflineIndicator)
    }
  })

  // Optimize scroll and resize by delaying some renders
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    // window.addEventListener('scroll', handleScroll);
    return function cleanup() {
      window.removeEventListener('resize', handleResize)
      // window.removeEventListener('scroll', handleScroll);
    }
  })

  const changeLocale = (code) => {
    // Only if user select a language and does not press cancel, try to set new locale code
    if (code) {
      console.log('Set locale', code)
      setLocale(code)
      context.saveLocale(code)
      setMessages(locales[code])
    }
  }

  const localeOptions = () => {
    let options = []
    for (let code in locales) {
      options.push({ key: code, value: locales[code].locale })
    }
    return options
  }

  const [hasError, setHasError] = React.useState(false)

  // Insert <Bomb/> in HTML tag to test error handling or call /bomb in URL
  // eslint-disable-next-line
  function Bomb() {
    throw new Error('💥 CABOOM 💥')
  }

  const getTitle = () => {
    if (!document.location.hostname.endsWith('storybook.events')) {
      return (
        <div
          style={{ width: 200, cursor: 'pointer' }}
          onClick={() => (document.location.href = '/')}
        >
          {manifest.name}
        </div>
      )
    }
    return (
      <div
        style={{ width: 200, cursor: 'pointer' }}
        onClick={() => (document.location.href = '/')}
      >
        <div className="moon"></div>
        <span className="shadow">STORYBOOK</span>
        <sup
          className="shadow"
          style={{ fontSize: '70%', marginLeft: 0, marginTop: -5 }}
        >
          events
        </sup>
      </div>
    )
  }

  return (
    <IntlProvider locale={locale} messages={messages} defaultLocale={'en-UK'}>
      {/*<BugsnagErrorBoundary>*/}

      <ErrorBoundary
        fallbackRender={({ error }) => (
          <div role="alert" style={{ textAlign: 'center' }}>
            <div>Error message</div>
            <pre>{error.message}</pre>
            <button
              style={{
                color: '#FFFFFF',
                backgroundColor: '#00DD00',
                height: 30,
                borderRadius: 15,
                border: 1,
              }}
              onClick={() => {
                setHasError(false)
                document.location.reload()
              }}
            >
              Try again
            </button>
            <button
              style={{
                color: '#FFFFFF',
                backgroundColor: '#DD0000',
                height: 30,
                borderRadius: 15,
                border: 1,
              }}
              onClick={() => {
                setHasError(false)
                document.location.href = '/'
              }}
            >
              Go home
            </button>
          </div>
        )}
        onReset={() => {
          setHasError(false)
        }}
        onError={(error, info) => {
          console.log(error)
        }}
        resetKeys={[hasError]}
      >
        <div
          className="System-update"
          style={{ display: systemUpdateText != null ? 'block' : 'none' }}
        >
          {systemUpdateText != null ? systemUpdateText : ''}
        </div>

        {/* Load Message MetaData for SEO and SoMe based on url implies type html */}
        {document.location.pathname &&
          !document.location.pathname.startsWith('/admin') && (
            <MessageList
              type="url"
              name={document.location.pathname}
              state="final"
              change={setPageContent}
            />
          )}
        {page && <MessageMetaData message={page} />}

        <CssBaseline />
        <CookiesProvider>
          {document.location.pathname === '/bomb' ? <Bomb /> : null}

          <Grid container className="App" spacing={0}>
            <Grid item xs={12}>
              {/* https://www.fiverr.com/nageenasif/create-2d-animated-gif */}
              <header className={classes.appHeader}>
                <img
                  alt=""
                  src={loading ? '/images/globe.gif' : '/images/globe.png'}
                  style={{
                    position: 'absolute',
                    width: 48,
                    height: 48,
                    left: 4,
                    top: 8,
                  }}
                />
                <IconButton
                  style={{ color: '#FFFFFF' }}
                  color="primary"
                  aria-label="add to home screen"
                  onClick={() => addToHomeScreen()}
                >
                  {showAddToHomeScreen ? (
                    <AddToHomeScreenIcon />
                  ) : (
                    <ShareIcon />
                  )}
                </IconButton>

                {/*<img src="/images/book.jpg" className="still-logo" alt="logo" />*/}
                <div className="title">{getTitle()}</div>
                {/*<img src="/images/events.jpg" className="still-logo" alt="upcoming-events" />*/}

                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  variant="dot"
                  badgeContent={badgeContent}
                  showZero={false}
                >
                  <Avatar
                    alt="User profile image"
                    src={avatarURL}
                    className={classes.avatar}
                    onClick={() => {
                      document.location.href = '/my-page'
                    }}
                  >
                    {avatarURL === '' ? (
                      hasValidSession() ? (
                        <HowToRegIcon />
                      ) : (
                        <PersonIcon />
                      )
                    ) : null}
                  </Avatar>
                </StyledBadge>

                <Card
                  className={classes.card}
                  style={{ display: teaserAccepted ? 'none' : 'block' }}
                >
                  <CardActionArea>
                    <CardContent className={classes.cardContent}>
                      <CardMedia
                        component="img"
                        alt="Instant travel"
                        height="112"
                        image="/images/travel-planning.png"
                        title="Travel planning"
                      />
                      <MessagePlaceholder
                        list={messageList}
                        name="Frontpage teaser"
                        value=""
                      />
                    </CardContent>
                  </CardActionArea>
                  <CardActions className={classes.cardActions}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => showSharePrompt()}
                    >
                      <ShareIcon style={{ marginRight: 5 }} /> Share
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={(event) => (document.location.href = '/faq')}
                    >
                      <MenuBookIcon style={{ marginRight: 5 }} /> Learn More
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={(event) => {
                        setTeaserAccepted(true)
                        setTeaser(true)
                      }}
                    >
                      OK
                    </Button>
                  </CardActions>
                </Card>
              </header>

              <div className="App-content">
                <Switch>
                  <Route exact path="/">
                    <SearchEvent />
                  </Route>

                  <Route exact path="/events">
                    <SearchEvent />
                  </Route>

                  <Route exact path="/products">
                    <SearchProduct />
                  </Route>

                  <Route path="/signup">
                    <Signup />
                  </Route>

                  <Route path="/login">
                    <Login />
                  </Route>

                  <Route path="/password">
                    <ChangePassword />
                  </Route>

                  <Route path="/reset-password">
                    <ResetPassword />
                  </Route>

                  <Route path="/contact">
                    <Contact />
                  </Route>

                  <Route path="/faq">
                    <FAQ />
                  </Route>

                  <Route path="/event/:eventId/view">
                    <EventView />
                  </Route>

                  <Suspense fallback={<div>Loading...</div>}>
                    <PrivateRoute path="/admin/*" user={user} role="admin">
                      <Route path="/admin/messages">
                        <MessageDataGrid />
                      </Route>

                      <Route path="/admin/notes">
                        <NoteDataGrid />
                      </Route>

                      <Route path="/admin/topics">
                        <TopicDataGrid />
                      </Route>

                      <Route path="/admin/calendar">
                        <CalendarDataGrid />
                      </Route>

                      <Route path="/admin/exception">
                        <ExceptionDataGrid />
                      </Route>

                      <Route path="/admin/queries">
                        <QueryDataGrid />
                      </Route>

                      <Route path="/admin/users">
                        <UserDataGrid />
                      </Route>

                      <Route path="/admin/payments">
                        <PaymentTransactionDataGrid />
                      </Route>

                      <Route path="/admin/orders">
                        <OrderDataGrid />
                      </Route>

                      <Route path="/admin/logs">
                        <LogDataGrid />
                      </Route>

                      <Route path="/admin/seqman">
                        <SequelizeManager />
                      </Route>

                      <Route path="/admin/solrad">
                        <SolrAD />
                      </Route>

                      <Route path="/admin/customers">
                        <CustomerDataGrid />
                      </Route>

                      <Route path="/admin/products">
                        <ProductDataGrid />
                      </Route>

                      <Route path="/admin/usergroups">
                        <UserGroupDataGrid />
                      </Route>

                      <Route path="/admin/classes">
                        <ClassDataGrid />
                      </Route>

                      <Route path="/admin/events">
                        <EventDataGrid />
                      </Route>

                      <Route path="/admin/event-planner">
                        <EventPlannerDataGrid />
                      </Route>

                      <Route path="/admin/event-participant">
                        <EventParticipantDataGrid />
                      </Route>

                      <Route path="/admin/courses">
                        <CourseDataGrid />
                      </Route>

                      <Route path="/admin/records">
                        <RecordDataGrid />
                      </Route>

                      <Route path="/admin/documents">
                        <DocumentDataGrid />
                      </Route>

                      <Route path="/admin/dashboard">
                        <Dashboard />
                      </Route>
                    </PrivateRoute>

                    <PrivateRoute>
                      <Route path="/my-page">
                        <MyPage />
                      </Route>

                      <Route path="/openstreetmap">
                        <OpenStreetMap />
                      </Route>

                      <Route path="/calendar/:isoDate?">
                        <EventCalendar />
                      </Route>

                      <Route path="/event/:eventId/edit">
                        <Event />
                      </Route>

                      <Route path="/record/:recordId/edit">
                        <Record />
                      </Route>

                      <Route path="/document/:documentId/edit">
                        <Document />
                      </Route>

                      <Route path="/message/:messageId/edit">
                        <Message />
                      </Route>

                      <Route path="/query/:queryId/view">
                        <QueryRunner />
                      </Route>

                      <Route path="/query/:queryId/edit">
                        <QueryBuilder />
                      </Route>

                      <Route path="/whiteboard">
                        <Whiteboard />
                      </Route>

                      <Route path="/meetup">
                        <Meetup />
                        {/* 
                          // Test embedded WhereBy video conference 
                          <MeetingRoom user={user} />
                        */}
                      </Route>

                      <Route path="/event/:eventId/meeting">
                        <MeetingRoom user={user} />
                      </Route>

                      <Route path="/payment/cancel/:paymentTransactionId?">
                        <PaymentCancel />
                      </Route>

                      <Route path="/payment/continue/:paymentTransactionId?">
                        <PaymentContinue />
                      </Route>

                      <Route path="/payment/checkout">
                        <Checkout />
                      </Route>

                      <Route path="/quickpay/test">
                        <QuickPay />
                      </Route>
                      <Route path="/quickpay/cancel">
                        <QuickPayCancel />
                      </Route>
                      <Route path="/quickpay/continue">
                        <QuickPayContinue />
                      </Route>
                    </PrivateRoute>
                  </Suspense>
                </Switch>
              </div>

              <Navigation
                user={user}
                currentLocale={getLocale}
                changeLocale={changeLocale}
                localeOptions={localeOptions}
              />

              <ConfirmDialog
                open={confirmDialogOpen}
                title={confirmDialogTitle}
                content={confirmDialogContent}
                onClose={confirmDialogClose}
                onConfirm={confirmDialogConfirm}
                value={confirmDialogValue}
              />
            </Grid>
          </Grid>
        </CookiesProvider>
      </ErrorBoundary>
      {/*</BugsnagErrorBoundary>*/}
    </IntlProvider>
  )
}

export default withRouter(withContext(withCookies(App)))
