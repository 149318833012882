import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import '../App.css'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 100,
  },
  form: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  header: {
    width: '100%',
    textAlign: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  box: {
    margin: 10,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    border: '1px solid #DDDDDD',
    borderRadius: 20,
    boxShadow: '5px 5px #EEEEEE',
    width: 300,
    backgroundColor: '#FFFFFF',
  },
  list: {
    margin: 10,
    padding: 10,
  },
  listItem: {
    paddingBottom: 10,
  },
  link: {
    margin: theme.spacing(1),
    marginLeft: 0,
    textDecoration: 'none',
    color: '#002460',
  },
}))

function FAQ(props) {
  const classes = useStyles()
  /*
  const [values, setValues] = React.useState({
    email: '',
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  */

  const handleDownload = () => {
    let downloadUrl = '/terms-of-use.pdf'
    window.open(
      downloadUrl,
      '_blank',
      'toolbar=0,location=no,directories=0,status=0,scrollbars=0,resizeable=0,width=' +
        window.innerWidth +
        ',height=' +
        window.innerHeight +
        ',top=0,left=0',
    )
    window.focus()
  }

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate autoComplete="off">
        <div className={classes.box}>
          <h2 className={classes.header}>Frequently asked questions</h2>
          <ul className={classes.list}>
            <li className={classes.listItem}>
              <Link
                className={classes.link}
                onClick={() => {
                  document.location.href = '/contact'
                }}
                style={{ width: '100%' }}
                color="primary"
              >
                Customer service
              </Link>
            </li>

            <li className={classes.listItem}>
              <Link
                className={classes.link}
                onClick={() => {
                  document.location.href = '/contact'
                }}
                style={{ width: '100%' }}
                color="primary"
              >
                Product and services
              </Link>
            </li>

            <li className={classes.listItem}>
              <Link
                className={classes.link}
                onClick={() => {
                  document.location.href = '/contact'
                }}
                style={{ width: '100%' }}
                color="primary"
              >
                Payment methods and rules
              </Link>
            </li>

            <li className={classes.listItem}>
              <Link
                className={classes.link}
                onClick={handleDownload}
                style={{ width: '100%' }}
                color="primary"
              >
                Download Terms of Use
              </Link>
            </li>
          </ul>
        </div>
      </form>
    </div>
  )
}

export default FAQ
