import React, { useEffect } from 'react'
import { getSession, fetchWithTimeout } from '../../utils'

// Pass callback to parent context setMessageList
function MessageList(props) {
  let { name, type, state, change } = props
  if (!name) throw new Error('MessageList is missing name property')
  if (!type) type = 'html'
  if (!state) state = 'final'

  // messageType      mail,sms,pdf,html (default html)
  // messageName      Internal unique message name in English
  // messageState     draft,pending,final (default final)
  // handleError      Error handler callback function
  const getMessageList = async (
    messageType,
    messageName,
    messageState,
    handleError,
  ) => {
    let json
    if (!messageType) messageType = 'html'
    if (!messageState) messageState = 'final'
    const session = getSession()
    if (!session || !session.token) console.log('Session token undefined')
    const requestURL = `/api/v1/message/list/${encodeURIComponent(
      messageType,
    )}/${encodeURIComponent(messageName)}/${encodeURIComponent(messageState)}`
    let res = await fetchWithTimeout(requestURL, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (session ? session.token : ''),
      },
      timeout: 3000,
    })
    if (res) {
      if (res.status === 200) {
        json = await res.json()
      }
    }
    return json
  }

  // ComponentDidMount
  useEffect(() => {
    const getContent = async () => {
      const json = await getMessageList(type, name, state, (err) => {
        console.log('ERROR: getMessageList:', err)
      })
      if (json && json.messageList) {
        // console.log('MessageList:',json.messageList);
        if (change) change(json.messageList)
      }
    }
    getContent()
    // eslint-disable-next-line
  }, [])

  return <>{/* Message component */}</>
}

export default MessageList
