import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import WorkIcon from '@material-ui/icons/Work'
import BeachAccessIcon from '@material-ui/icons/BeachAccess'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'
import Button from '@material-ui/core/Button'
import '../App.css'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 100,
  },
  form: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  header: {
    width: '100%',
    textAlign: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  box: {
    margin: 10,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    border: '1px solid #DDDDDD',
    borderRadius: 20,
    boxShadow: '5px 5px #EEEEEE',
    width: 300,
    backgroundColor: '#FFFFFF',
  },
  button: {
    margin: theme.spacing(2),
    borderRadius: 20,
  },
}))

function Contact(props) {
  const classes = useStyles()
  /*
  const [values, setValues] = React.useState({
    email: '',
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  */

  const handleDownload = () => {
    let downloadUrl = '/terms-of-use.pdf'
    window.open(
      downloadUrl,
      '_blank',
      'toolbar=0,location=no,directories=0,status=0,scrollbars=0,resizeable=0,width=' +
        window.innerWidth +
        ',height=' +
        window.innerHeight +
        ',top=0,left=0',
    )
    window.focus()
  }

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate autoComplete="off">
        <div className={classes.box}>
          <h2 className={classes.header}>Customer service</h2>
          Olio PI ApS
          <br />
          CVR 42188840
          <br />
          Valdemarsgade 41, st. tv.
          <br />
          1665 Copenhagen V<br />
          Denmark
          <br />
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <a href="mailto:admin@olio.dk" style={{ color: 'inherit' }}>
                    <MailIcon />
                  </a>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="admin@olio.dk"
                secondary="Next business day support"
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PhoneIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="+45 2290 9122"
                secondary="English and Danish support"
              />
            </ListItem>
          </List>
          <Button
            onClick={handleDownload}
            style={{ width: '100%' }}
            color="primary"
          >
            Download Terms of Use
          </Button>
        </div>
        <div className={classes.box}>
          <h2 className={classes.header}>Products and services</h2>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ImageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Booking of online guided tours using video conference"
                secondary="from 15-50 euro per hour"
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WorkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Monthly subscription for guides to plan free tours"
                secondary="30 euro per month"
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <BeachAccessIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Pay for online tours in rates, if you ask a guide to spend several hours to research on your behalf"
                secondary="30 euro per month"
              />
            </ListItem>
          </List>
        </div>
        <div className={classes.box}>
          <h2 className={classes.header}>Payment methods and rules</h2>
          All payments will be listed in Euro (EUR) and the following credit
          cards can be used.
          <br />
          <ul style={{ listStyle: 'none' }}>
            {/*<li style={{ height: 100}}><img src="/images/dankort.svg" alt="Dankort logo" style={{ height: 80}} /></li>*/}
            <li style={{ height: 100 }}>
              <img
                src="/images/visa.svg"
                alt="Visa logo"
                style={{ height: 60 }}
              />
            </li>
            <li style={{ height: 100 }}>
              <img
                src="/images/mastercard.svg"
                alt="Mastercard logo"
                style={{ height: 80 }}
              />
              Mastercard
            </li>
          </ul>
          <h3>Five rules to remember</h3>
          <ol style={{ margin: 0, paddingInlineStart: 15 }}>
            <li>
              If you forget an event and the guide can document they waited 15
              minutes, by sending you a SMS and email reminder 15 minutes apart,
              then we pay the guide 50% as a no show fee.
            </li>
            <li>
              All upfront payments were guides buy tickets or other things on
              your behalf, are not refundable
            </li>
            <li>
              If you cancel an event in time, we refund the full payment, minus
              any upfront payments.
            </li>
            <li>
              All refunds are payed as a one year gift card,that can be used for
              any new event.
            </li>
            <li>
              If the guide has to cancel the tour due to sickness or other
              circumstances out of their control, then the guide is no longer
              entitled to payment for the tour that was booked or any upfront
              costs they paid.
            </li>
          </ol>
        </div>
      </form>
    </div>
  )
}

export default Contact
