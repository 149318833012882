import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
}))

function ConfirmOptionsDialog(props) {
  const {
    onClose,
    value: valueProp,
    open,
    options,
    title,
    cancel,
    ok,
    ...other
  } = props
  const [value, setValue] = React.useState(valueProp)
  const radioGroupRef = React.useRef(null)

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp)
    }
  }, [valueProp, open])
  /* Add dialof transposition for onEntering={handleEntering}
  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };
  */

  const handleCancel = () => {
    onClose()
  }

  const handleOk = () => {
    onClose(value)
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const classes = useStyles()

  return (
    <Dialog
      className={classes.root}
      maxWidth="xs"
      aria-labelledby="locale-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="locale-dialog-title">{title ? title : ''}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="locale"
          name="locale"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option.key}
              key={option.key}
              control={<Radio />}
              label={option.value}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {cancel ? cancel : 'Cancel'}
        </Button>
        <Button onClick={handleOk} color="primary">
          {ok ? ok : 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmOptionsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  title: PropTypes.string,
  cancel: PropTypes.string,
  ok: PropTypes.string,
}

export default ConfirmOptionsDialog
