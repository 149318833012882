import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { hasValidSession } from '../../utils'

// If you nest PrivateRoutes in a route Switch you can check
// if user is authenticated and check the user role or you can
// use the role in the path and place the most strict routes first
// <PrivateRoute path="/admin/*" user={user} role="admin">

function PrivateRoute(props) {
  // console.log(props);
  let { user, role, children, ...rest } = props
  let authenticated = hasValidSession()
  // Check if system update in progress
  let key = document.location.hostname + ':system-update'
  let systemUpdate = false
  if (typeof Storage !== 'undefined' && localStorage.getItem(key) === 'true')
    systemUpdate = true
  if (props.path && props.path.startsWith('/admin/')) systemUpdate = false
  // console.log('Private route',JSON.stringify({user, role, systemUpdate},null,2));
  // console.log(props.location.pathname);
  return (
    <Route
      {...rest}
      render={() => {
        return authenticated ? (
          systemUpdate === true ||
          (role !== undefined && (user === undefined || user.role !== role)) ? (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: window.location.pathname,
                },
              }}
            />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: window.location.pathname,
              },
            }}
          />
        )
      }}
    />
  )
}

export default PrivateRoute
