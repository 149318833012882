import { useRef } from 'react'

/*
Example usage of fetchWithTimeout

async function loadGames() {
  try {
    const response = await fetchWithTimeout('/games', {
      timeout: 6000
    });
    const games = await response.json();
    return games;
  } catch (error) {
    // Timeouts if the request takes
    // longer than 6 seconds
    console.log(error.name === 'AbortError');
  }
}

Links: 
https://developer.mozilla.org/en-US/docs/Web/API/AbortController 
https://dmitripavlutin.com/timeout-fetch-request
*/

// Output can be: json, text, log or undefined returning response as is
// Redirecting after fetch can cause "Failed to load response data" in devtools
export async function fetchWithTimeout(resource, options, output) {
  let log = output === 'log'
  // Make default options is not specied in options function argument
  if (!options) options = {}
  if (!options.mode) options.mode = 'cors'
  if (!options.headers) options.headers = {}
  if (!options.headers['Content-Type'])
    options.headers['Content-Type'] = 'application/json'
  // let contentType = options.headers['Content-Type'];
  // Upload files uses multipart/form-data, where the browser autos generate boundary in content-type
  // "Content-Type": "multipart/form-data; charset=utf-8;boundary=------WebKitFormBoundaryF0RBZBSar7PzOJpN",
  if (options.headers['Content-Type'].startsWith('multipart/form-data')) {
    console.log(
      'Deleted header Content-Type to allow browser to auto detect content type and add boundary for multipart/form-data',
    )
    delete options.headers['Content-Type']
  }
  const { timeout = 10000, method = 'GET' } = options

  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)
  const fetchOptions = {
    ...options,
    signal: controller.signal,
  }
  if (log)
    console.log('Fetch ' + method + ' ' + resource + ' with timeout ' + timeout)
  if (log) console.log('Options', JSON.stringify(fetchOptions, null, 2))
  const response = await fetch(resource, fetchOptions)
    .then(async (res) => {
      if (log) console.log(res)
      if (output === 'json') return await res.json()
      if (output === 'text') return await res.text()
      return res // return raw read stream
    })
    .catch((err) => {
      console.log('FetchWithTimeoutError', err.message, ' on ', resource)
      return err
    })
  clearTimeout(id)
  return response
}

export function getLocalStorageJson(name, json) {
  let startTime = Date.now()
  // Local storage provides 10MB were cookies are limited to 4093 bytes
  if (typeof Storage === 'undefined') {
    throw new Error('HTML5 local storage API not found, cant save any changes!')
  }
  // getItem return null if not found and then we return default json from input patameter
  let data = localStorage.getItem(name)
  if (data) {
    data = JSON.parse(data)
    let time = Date.now() - startTime
    if (time > 50) console.log('Get local storage json', name, 'in', time, 'ms')
    // If server settings are newer than client settings use them, this will
    // normally only happen first time, otherwise client is king, but if
    // something went very wrong we might choose to refresh from server
    if (json.timestamp > data.timestamp) {
      // console.log('Default json timestamp is never',name,json.timestamp,'>',data.timestamp)
      return json
    }
    // console.log('Local storage json is never',name);
    return data
  }
  return json
}

export function setLocalStorageJson(name, json) {
  let startTime = Date.now()
  // Local storage provides 10MB were cookies are limited to 4093 bytes
  if (typeof Storage === 'undefined') {
    throw new Error('HTML5 local storage API not found, cant save any changes!')
  }

  json.timestamp = new Date().toJSON()
  let data = JSON.stringify(json)
  localStorage.setItem(name, data)

  let time = Date.now() - startTime
  if (time > 50) console.log('Set local storage json', name, 'in', time, 'ms')
}

export const getCookie = (name) => {
  const cookieName = name + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let cookieArray = decodedCookie.split(';')
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length)
    }
  }
  return ''
}

// Helper functions
let localStorageKey = document.location.hostname + ':data'

export const getDomainData = () => {
  const data = getLocalStorageJson(localStorageKey, {})
  if (data) return data
  return undefined
}

export const setDomainData = (data) => {
  setLocalStorageJson(localStorageKey, data)
}

export const getUser = () => {
  const data = getLocalStorageJson(localStorageKey, {})
  if (data) return data.user
  return undefined
}

export const setUser = (user) => {
  const data = getLocalStorageJson(localStorageKey, {})
  data.user = user
  setLocalStorageJson(localStorageKey, data)
}

export const initLocale = 'en-UK'

export const initOrder = {
  orderLine: [],
  amount: '0.00', // Authorize max monthly amount displayed at checkout
  total: '0.00', // Total of all orderlines displayed at checkout
  currency: 'EUR', // Currency displayed at checkout
  description: '', // Description displayed at checkout
}

export const initOrderLine = {
  prodcutId: 0,
  productName: '',
  quantity: 0,
  price: 0, // Price per unit in smallest unit within currency 100 = 1.00 euro
  total: 0, // Total amount using smallest unit within currency, to avoid comma issues
  unit: 'EUR', // currency for units above
}

export const getOrder = () => {
  const data = getLocalStorageJson(localStorageKey, {})
  if (data.order) return data.order
  data.order = initOrder
  setLocalStorageJson(localStorageKey, data)
  return data.order
}

export const setOrder = (order) => {
  const data = getLocalStorageJson(localStorageKey, {})
  data.order = order ? order : initOrder
  setLocalStorageJson(localStorageKey, data)
}

export const getLocale = () => {
  const data = getLocalStorageJson(localStorageKey, {})
  if (data.locale) return data.locale
  data.locale = initLocale
  setLocalStorageJson(localStorageKey, data)
  return data.locale
}

export const setLocale = (locale) => {
  const data = getLocalStorageJson(localStorageKey, {})
  data.locale = locale ? locale : initLocale
  setLocalStorageJson(localStorageKey, data)
}

export const addOrderLine = (orderLine) => {
  const data = getLocalStorageJson(localStorageKey, {})
  if (!data.order) data.order = initOrder
  if (orderLine) data.order.orderLines.push(orderLine)
  setLocalStorageJson(localStorageKey, data)
}

export const delOrderLine = (orderLineIndex) => {
  const data = getLocalStorageJson(localStorageKey, {})
  if (!data.order) data.order = initOrder
  if (orderLineIndex >= 0 && orderLineIndex < data.order.orderLines.length) {
    data.order.orderLines.splice(orderLineIndex, 1) // delete order line index
  }
  setLocalStorageJson(localStorageKey, data)
}

export const getSession = () => {
  const data = getLocalStorageJson(localStorageKey, {})
  if (data) return data.session
  return undefined
}

export const setSession = (session) => {
  const data = getLocalStorageJson(localStorageKey, {})
  data.session = session ? session : { token: undefined, expires: undefined }
  setLocalStorageJson(localStorageKey, data)
}

export const hasValidSession = () => {
  const data = getLocalStorageJson(localStorageKey, {})
  if (data && data.session) {
    const now = new Date().getTime()
    if (data.session.token && new Date(data.session.expires).getTime() > now)
      return true
  }
  return false
}

export const getTeaser = () => {
  const data = getLocalStorageJson(localStorageKey, {})
  if (data) return data.teaser
  return undefined
}

export const setTeaser = (value) => {
  const data = getLocalStorageJson(localStorageKey, {})
  data.teaser = value
  setLocalStorageJson(localStorageKey, data)
}

export const toDeg = (angleInRadians) => {
  return (angleInRadians * 180) / Math.PI
}

export const toRad = (angleInDegrees) => {
  return (angleInDegrees * Math.PI) / 180
}

// US: 234 Test Road, Testville
// DK: Test Road 234, Testville
export const splitAddress = (address) => {
  // console.log('ADDRESS',address);
  let obj = { address: '', houseNo: '', houseExt: '' }
  if (address) {
    let regex = new RegExp(/\d[0-9]+/g)
    let i = address.search(regex)
    if (i >= 0) {
      const matches = address.match(regex)
      // console.log('Matches',matches);
      obj = {
        address: address.substring(0, i).trim(),
        houseNo: matches[0],
        houseExt: address
          .substring(i + matches[0].length)
          .replace(',', '')
          .trim(),
      }
    }
  }
  return obj
}

/*
Credentials API is only supported on Chrome.
https://caniuse.com/?search=navigator.credentials
https://developer.mozilla.org/en-US/docs/Web/API/PasswordCredential/PasswordCredential

export function storeCredential(formId) {
  if (!navigator.credentials) {
    console.log('Credential Management API not supported');
    return;
  }
  
  let credentialForm = document.getElementById(formId);
  let credential = new window.PasswordCredential(credentialForm);
  navigator.credentials.store(credential)
    .then(() => console.log('Storing credential for ' + credential.id + ' (result cannot be checked by the website).'))
    .catch((err) => console.log('Error storing credentials: ' + err));
}

//  mediation: silent, optional or required
export function requestCredential(formId, mediation) {  
  if (!navigator.credentials) {
    console.log('Credential Management API not supported');
    return;
  }
  if (!mediation) mediation = "optional";
  
  navigator.credentials.get({password: true, mediation})
    .then(credential => {
      let result = 'none';
      if (credential) {
        result = credential.id + ', ' + credential.password.replace(/./g, '*');
      }
      console.log('Credential read: ' + result + '');
    })
    .catch((err) => console.log('Error reading credentials: ' + err));
}

export function preventSilentAccess() {
  if (!navigator.credentials) {
    console.log('Credential Management API not supported');
    return;
  }
  
  navigator.credentials.preventSilentAccess()
    .then(() => console.log('Silent access prevented (mediation will be required for next credentials.get() call).'))
    .catch((err) => console.log('Error preventing silent access: ' + err));
}

*/

export function deepCompareEquals(a, b) {
  // TODO: implement deep comparison here
  // something like lodash
  // return _.isEqual(a, b);
}

export function useDeepCompareMemoize(value) {
  const ref = useRef()
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export function errorIgnoreList(list) {
  // In development suppress warning that take to much focus and cant be fixed right now
  if (process.env.NODE_ENV === 'development') {
    // Ignore errors that can not be fixed right now, waiting for dependency libraries to fix these errors
    let errorIgnoreList = ['Warning: findDOMNode is deprecated in StrictMode.']
    if (list) errorIgnoreList = list
    const error = console.error
    console.error = function () {
      if (typeof arguments[0] === 'string' && arguments.length > 1) {
        let message = arguments[0].replace(/%s/g, arguments[1])
        let stackTrace = arguments[arguments.length - 1]
        for (let i in errorIgnoreList) {
          if ((message + stackTrace).indexOf(errorIgnoreList[i]) >= 0) return
        }
        console.log(message)
        console.log(stackTrace)
        return
      }
      error(arguments)
    }
  }
}
