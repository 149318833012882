import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import FormGroup from '@material-ui/core/FormGroup'
import '../App.css'

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    display: 'flex',
    justifyContent: 'center', // horizontal align
    flexWrap: 'wrap',
  },
  header: {
    width: '100%',
    textAlign: 'center',
  },
  box: {
    margin: 10,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    border: '1px solid #DDDDDD',
    borderRadius: 20,
    boxShadow: '5px 5px #EEEEEE',
    width: 300,
    backgroundColor: '#FFFFFF',
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    margin: 0,
    width: '100%',
  },
  button: {
    margin: theme.spacing(2),
    width: 120,
    borderRadius: 20,
  },
}))

function ResetPassword(props) {
  const classes = useStyles()
  const [values, setValues] = React.useState({
    username: '',
    password: '',
    showPassword: false,
    showChecked: false,
    email: '',
    mobile: '',
    loginHint: '',
    passwordHint: '',
  })

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleResetPassword = (event) => {
    event.preventDefault()
  }

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate autoComplete="off">
        <div className={classes.box}>
          <FormGroup row style={{ paddingBottom: 50 }}>
            <h2 className={classes.header}>Reset password</h2>
            <p>
              Specify at least one information to identify your user account
            </p>
            <FormControl
              className={clsx(
                classes.margin,
                classes.withoutLabel,
                classes.textField,
              )}
            >
              <InputLabel htmlFor="username">Username</InputLabel>
              <Input
                id="username"
                value={values.username}
                onChange={handleChange('username')}
                aria-describedby="username-helper-text"
                inputProps={{
                  'aria-label': 'username',
                }}
              />
              <FormHelperText id="username-helper-text">
                {values.loginHint}
              </FormHelperText>
            </FormControl>

            <FormControl
              className={clsx(
                classes.margin,
                classes.withoutLabel,
                classes.textField,
              )}
            >
              <InputLabel htmlFor="email">E-mail</InputLabel>
              <Input
                id="email"
                value={values.email}
                onChange={handleChange('email')}
                inputProps={{
                  'aria-label': 'email',
                }}
              />
            </FormControl>

            <FormControl
              className={clsx(
                classes.margin,
                classes.withoutLabel,
                classes.textField,
              )}
            >
              <InputLabel htmlFor="mobile">Mobile</InputLabel>
              <Input
                id="mobile"
                value={values.mobile}
                onChange={handleChange('mobile')}
                inputProps={{
                  'aria-label': 'mobile',
                }}
              />
            </FormControl>

            <br />
            <br />

            <div className={classes.actions}>
              <Button
                variant="contained"
                onClick={handleResetPassword}
                color="primary"
                className={classes.button}
              >
                Send link
              </Button>
            </div>
          </FormGroup>
        </div>
      </form>
    </div>
  )
}

export default ResetPassword
