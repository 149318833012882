/**
 * @module Export all locale translations
 */

import english from './en-UK.json'
import danish from './da-DK.json'

let locales = {
  'en-UK': english,
  'da-DK': danish,
}

export default locales
