import React, { useState, useEffect, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

// import Button from '../button';
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
// import MenuBookIcon from '@material-ui/icons/MenuBook';
import SearchIcon from '@material-ui/icons/Search'

import { makeStyles } from '@material-ui/core/styles'
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'
import ImageListItemBar from '@material-ui/core/ImageListItemBar'
// import ListSubheader from '@material-ui/core/ListSubheader';
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import InfoIcon from '@material-ui/icons/Info'
import PersonIcon from '@material-ui/icons/Person'
import StarRateIcon from '@material-ui/icons/StarRate'
import StarOutlineIcon from '@material-ui/icons/StarOutline'
import './search-product.css'
import { fetchWithTimeout } from '../../utils'
import { DateTime } from 'luxon'
import { Context } from '../../context/Context'

const imageWidth = 300
const imageHeight = 450

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  search: {
    width: 194,
  },
  media: {
    width: '100%',
    objectFit: 'cover',
  },
}))

function srcset(image, size, rows = 1, cols = 1) {
  let hasImage = image !== null && image !== '' && image !== undefined
  if (!hasImage) image = 'images/default-product-300x450.jpg'
  image = (process.env.PUBLIC_URL ? process.env.PUBLIC_URL : '') + image
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  }
}

export const SearchProduct = (props) => {
  const context = useContext(Context)
  const { onSearch } = props
  const classes = useStyles()
  /*
  // getData((process.env.PUBLIC_URL?process.env.PUBLIC_URL:'')+'/images/'+item.author, 'text/html')
  const getData=(url, contentType)=>{
    fetch(url, { headers : { 'Content-Type': contentType, 'Accept': contentType }})
    .then(function (response) {
      return response.text();
    })
    .then(function (text) {
      var parser = new DOMParser();
    	var html = parser.parseFromString(text, 'text/html');
      // console.log(html.body);
      return html.body;
    })
    .catch(function (err) {
      console.warn('Get data error:', err);
    });
  }
  */

  // eslint-disable-next-line
  const [view, setView] = useState('grid')
  const [productList, setProductList] = useState([])
  const [filteredProductList, setFilteredProductList] = useState([])
  const [startDate, setStartDate] = useState(DateTime.now())
  const [searchText, setSearchText] = useState('')
  const [cols, setCols] = useState(window.innerWidth <= 600 ? 4 : 8)
  const [rowHeight, setRowHeight] = useState(
    window.innerWidth <= 600 ? 200 : 300,
  )

  const getFilteredProductList = (productList) => {
    let fromDate = startDate.toISODate()
    let showList = productList.filter((item) => {
      // Filter on future events only
      if (item.startdate < fromDate) return false
      // Filter on event domain comma separated list contains browser main domain name
      let domain = document.location.hostname.split('.').slice(-2).join('.')
      if (item.domain && item.domain.split(',').indexOf(domain) < 0)
        return false
      // Filter on selected topics
      if (item.topic) {
        let productTopics = item.topic
          .split(',')
          .map((topic) => topic.toLowerCase().trim())
        // Lowercase topic keys
        if (context.state.search.topics) {
          let searchTopics = Object.keys(context.state.search.topics)
          if (searchTopics.length > 0) {
            const filteredArray = productTopics.filter((topic) =>
              searchTopics.includes(topic),
            )
            if (filteredArray.length === 0) return false
          }
        }
      }
      // Search for text in title and auther event fields
      let text = (item.title + ' ' + item.author).toLowerCase()
      if (searchText.length >= 2 && text.indexOf(searchText.toLowerCase()) < 0)
        return false
      return true
    })
    return showList
    // eslint-disable-next-line
  }

  /* Get publsied product with access=open and startdate and enddate set accordingly or null
  {
    "startdate": "2022-09-02",
    "enddate": "2023-02-24",
    "productList": [
      {
        "id": 1,
        "productGroupId": null,
        "userId": 1,
        "name": "Subscription",
        "description": null,
        "price": 69,
        "unit": "DKK",
        "access": "open",
        "startdate": "2022-09-30",
        "enddate": null,
        "syncronizedAt": null,
        "syncronizedTo": null,
        "createdAt": "2022-09-02T17:05:29.000Z",
        "updatedAt": null,
        "User": {
          "firstname": "Administrator",
          "surname": "",
          "birthday": "2000-01-01",
          "gender": "neutral",
          "email": "admin@olio.dk",
          "phone": "22909122",
          "avatarURL": "/images/avatar/nikolaj250x250.png"
        }
      },
  ...    
*/

  const getPublishedProducts = useCallback(async () => {
    let res = await fetchWithTimeout(
      '/api/v1/products?startdate=' + startDate.toISODate(),
    )
    if (res && res.status === 200) {
      let json = await res.json()
      setProductList(json.productList)
      setFilteredProductList(getFilteredProductList(json.productList))
      // console.log('Product',JSON.stringify(json.eventList,null,2));
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getPublishedProducts()
  }, [getPublishedProducts])

  const showProduct = (productId) => (product) => {
    console.log('Show product id', productId)
    if (productId) document.location.href = '/product/' + productId + '/view'
  }

  const handleKeyPress = (event) => {
    // if (event.key === 'Enter') {
    let filteredProductList = getFilteredProductList(productList)
    if (filteredProductList.length === 1) {
      setCols(2)
      setRowHeight(450)
    } else if (filteredProductList.length === 2) {
      setCols(4)
      setRowHeight(450)
    } else {
      setCols(window.innerWidth <= 600 ? 4 : 8)
      setRowHeight(window.innerWidth <= 600 ? 200 : 300)
    }
    setFilteredProductList(filteredProductList)
    event.preventDefault()
    //}
  }

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value)
  }

  // Show filter for 6 months
  const monthFilter = () => {
    let today = DateTime.now()
    let plus1 = today.plus({ month: 1 })
    let plus2 = today.plus({ month: 2 })
    let plus3 = today.plus({ month: 3 })
    let plus4 = today.plus({ month: 4 })
    let plus5 = today.plus({ month: 5 })
    let filter = []
    if (window.innerWidth <= 600) {
      filter = [
        <span key="month0" onClick={(event) => setStartDate(today)}>
          {today.monthShort.substring(0, 3)}
        </span>,
        <span key="month1" onClick={(event) => setStartDate(plus1)}>
          {plus1.monthShort.substring(0, 3)}
        </span>,
        <span key="month2" onClick={(event) => setStartDate(plus2)}>
          {plus2.monthShort.substring(0, 3)}
        </span>,
        <span key="month3" onClick={(event) => setStartDate(plus3)}>
          {plus3.monthShort.substring(0, 3)}
        </span>,
        <span key="month4" onClick={(event) => setStartDate(plus4)}>
          {plus4.monthShort.substring(0, 3)}
        </span>,
        <span key="month5" onClick={(event) => setStartDate(plus5)}>
          {plus5.monthShort.substring(0, 3)}
        </span>,
      ]
    } else {
      filter = [
        <span key="month0" onClick={(event) => setStartDate(today)}>
          {today.monthLong}
        </span>,
        <span key="month1" onClick={(event) => setStartDate(plus1)}>
          {plus1.monthLong}
        </span>,
        <span key="month2" onClick={(event) => setStartDate(plus2)}>
          {plus2.monthLong}
        </span>,
        <span key="month3" onClick={(event) => setStartDate(plus3)}>
          {plus3.monthLong}
        </span>,
        <span key="month4" onClick={(event) => setStartDate(plus4)}>
          {plus4.monthLong}
        </span>,
        <span key="month5" onClick={(event) => setStartDate(plus5)}>
          {plus5.monthLong}
        </span>,
      ]
    }
    return filter
  }

  return (
    <div className="search-wrapper">
      <div className="search-filter">
        <div>
          <TextField
            id="search"
            value={searchText}
            onKeyUp={handleKeyPress}
            onChange={handleChangeSearchText}
            className={classes.search}
          />
          <IconButton
            id="searchButton"
            onClick={onSearch}
            aria-label="search text"
          >
            <SearchIcon />
          </IconButton>

          <IconButton
            id="searchButton"
            onClick={(event) => {
              setView(view === 'grid' ? 'list' : 'grid')
            }}
            aria-label="search text"
          >
            {view === 'grid' ? <ViewColumnIcon /> : <ViewCompactIcon />}
          </IconButton>
        </div>
      </div>

      <div className="search-result-header">{monthFilter()}</div>

      <div className="search-result">
        {view === 'list'
          ? filteredProductList.map((item, index) => (
              <Paper
                key={'item-' + index}
                className="search-result-item"
                style={{
                  backgroundImage:
                    "url('" +
                    (process.env.PUBLIC_URL ? process.env.PUBLIC_URL : '') +
                    (item.imageURL !== null
                      ? item.imageURL
                      : 'images/default-product-300x450.jpg') +
                    "')",
                }}
                onClick={showProduct(item.id)}
              >
                <div className="search-result-item-title">
                  {item.title ? item.title : ''}
                </div>
                <div className="search-result-item-content">
                  <Avatar
                    style={{
                      backgroundColor: 'transparent',
                      marginTop: -32,
                      marginLeft: 5,
                    }}
                    alt="User profile image"
                    src={
                      item.User && item.User.avatarURL
                        ? item.User.avatarURL
                        : ''
                    }
                  >
                    <IconButton
                      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      aria-label={`info about ${item.title}`}
                    >
                      <PersonIcon />
                    </IconButton>
                  </Avatar>
                  <div style={{ display: 'flex', width: '100%' }}>
                    {['1', '2', '3', '4', '5'].map((rate, index) => {
                      return index < item.rating ? (
                        <StarRateIcon key={'rating-' + index} />
                      ) : (
                        <StarOutlineIcon
                          style={{ color: '#FFFFFF', opacity: 0.2 }}
                          key={'rating-' + index}
                        />
                      )
                    })}

                    <a
                      className="credit"
                      style={{ display: item.creditURL ? 'block' : 'none' }}
                      href={
                        (process.env.PUBLIC_URL ? process.env.PUBLIC_URL : '') +
                        item.creditURL
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InfoIcon
                        style={{ padding: 3, color: '#FFFFFF', opacity: 0.5 }}
                      />
                    </a>
                  </div>
                </div>
              </Paper>
            ))
          : null}

        {view === 'grid' ? (
          <ImageList
            sx={{ width: imageWidth, height: imageHeight }}
            variant="quilted"
            cols={cols}
            rowHeight={rowHeight}
            style={{ padding: 5 }}
          >
            {filteredProductList.map((item, index) => (
              <ImageListItem
                key={'item-' + index}
                cols={item.cols ? item.cols : item.rating >= 4 ? 4 : 2}
                rows={item.rows || 1}
                onClick={showProduct(item.id)}
              >
                <img
                  className={classes.media}
                  {...srcset(item.imageURL, imageWidth, item.rows, item.cols)}
                  alt={item.title}
                  loading={index >= cols ? 'lazy' : 'eager'}
                />
                <ImageListItemBar
                  className="search-result-item-bar"
                  title={item.title}
                  subtitle={item.author}
                  actionIcon={
                    <Avatar
                      style={{
                        backgroundColor: 'transparent',
                        marginRight: 5,
                        float: 'right',
                      }}
                      alt="User profile image"
                      src={
                        item.User && item.User.avatarURL
                          ? item.User.avatarURL
                          : ''
                      }
                    >
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${item.title}`}
                      >
                        <PersonIcon />
                      </IconButton>
                    </Avatar>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        ) : null}
        <div style={{ height: 200, width: '100%' }}></div>
      </div>
    </div>
  )
}

SearchProduct.propTypes = {
  onSearch: PropTypes.func,
}

SearchProduct.defaultProps = {
  onSearch: null,
}

export default SearchProduct
