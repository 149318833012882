import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Check from '@material-ui/icons/Check'
import Keyboard from '@material-ui/icons/Keyboard'
import Button from '@material-ui/core/Button'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import PersonIcon from '@material-ui/icons/Person'
//import Link from '@material-ui/core/Link';
import Favorite from '@material-ui/icons/Favorite'
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import '../App.css'

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    display: 'flex',
    justifyContent: 'center', // horizontal align
    flexWrap: 'wrap',
  },
  header: {
    width: '100%',
    textAlign: 'center',
  },
  box: {
    margin: 10,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    border: '1px solid #DDDDDD',
    borderRadius: 20,
    boxShadow: '5px 5px #EEEEEE',
    width: 300,
    backgroundColor: '#FFFFFF',
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    margin: 0,
    width: '100%',
  },
  button: {
    margin: theme.spacing(2),
    width: 120,
    borderRadius: 20,
  },
}))

function Signup(props) {
  const classes = useStyles()
  const [values, setValues] = React.useState({
    // User account information
    username: '',
    password: '',
    showPassword: false,
    showChecked: false,
    email: '',
    mobile: '',
    termsAccepted: false,
    loginHint: 'Type unique username or e-mail',
    passwordHint: '',
    showUserAccount: true,
    showWelcome: false,
    open: false,
  })

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleSignup = (event) => {
    setValues({ ...values, showUserAccount: false, showWelcome: false })
    event.preventDefault()
  }

  const handleDialogOpen = (event) => {
    setValues({ ...values, open: true })
  }

  const handleDialogClose = () => {
    setValues({ ...values, open: false, termsAccepted: false })
  }

  const handleDialogAccept = () => {
    setValues({ ...values, open: false, termsAccepted: true })
  }

  const handleDownload = () => {
    let downloadUrl = '/terms-of-use.pdf'
    window.open(
      downloadUrl,
      '_blank',
      'toolbar=0,location=no,directories=0,status=0,scrollbars=0,resizeable=0,width=' +
        window.innerWidth +
        ',height=' +
        window.innerHeight +
        ',top=0,left=0',
    )
    window.focus()
  }

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate autoComplete="off">
        <div className={classes.box}>
          <FormGroup
            row
            style={{
              paddingBottom: 50,
              display: values.showUserAccount ? 'block' : 'none',
            }}
          >
            <h2 className={classes.header}>Create User Account</h2>
            <FormControl
              className={clsx(
                classes.margin,
                classes.withoutLabel,
                classes.textField,
              )}
            >
              <InputLabel htmlFor="username">Username</InputLabel>
              <Input
                id="username"
                value={values.username}
                onChange={handleChange('username')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showChecked ? <Check /> : <Keyboard />}
                    </IconButton>
                  </InputAdornment>
                }
                aria-describedby="username-helper-text"
                inputProps={{
                  'aria-label': 'username',
                }}
              />
              <FormHelperText id="username-helper-text">
                {values.loginHint}
              </FormHelperText>
            </FormControl>

            <FormControl
              className={clsx(
                classes.margin,
                classes.withoutLabel,
                classes.textField,
              )}
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                id="password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                aria-describedby="password-helper-text"
                inputProps={{
                  'aria-label': 'username',
                }}
              />
              <FormHelperText id="password-helper-text">
                {values.passwordHint}
              </FormHelperText>
            </FormControl>

            <FormControl
              className={clsx(
                classes.margin,
                classes.withoutLabel,
                classes.textField,
              )}
            >
              <InputLabel htmlFor="email">E-mail</InputLabel>
              <Input
                id="email"
                value={values.email}
                onChange={handleChange('email')}
                inputProps={{
                  'aria-label': 'email',
                }}
              />
            </FormControl>

            <FormControl
              className={clsx(
                classes.margin,
                classes.withoutLabel,
                classes.textField,
              )}
            >
              <InputLabel htmlFor="mobile">Mobile</InputLabel>
              <Input
                id="mobile"
                value={values.mobile}
                onChange={handleChange('mobile')}
                inputProps={{
                  'aria-label': 'mobile',
                }}
              />
            </FormControl>

            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleDialogOpen}
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                  name="termsAccepted"
                />
              }
              label="Accept Terms of Use"
              checked={values.termsAccepted}
            />

            <div className={classes.actions}>
              <Button
                variant="contained"
                onClick={handleSignup}
                color="primary"
                className={classes.button}
                endIcon={<MenuBookIcon />}
              >
                Signup
              </Button>
            </div>
          </FormGroup>

          <FormGroup
            row
            style={{
              paddingBottom: 50,
              display: values.showWelcome ? 'block' : 'none',
            }}
          >
            <h2 className={classes.header}>Welcome and next steps</h2>
            <ol>
              <li>
                You created a free user account and can attend free events, as
                soon as you press the e-mail and SMS link send to confirm your
                identify.
              </li>
              <li>
                To attend or offer paid events go to my page to fill out My
                location information, because your address is required for 3D
                secure payments.
              </li>
              <li>
                For matching you with the best guides, go to my page to fill out
                My profile information
              </li>
              <li>
                In the button navigation search menu you can creates your
                favorite searches, that help us suggest events to you.
              </li>
            </ol>
            <p>We wish you many happy online travel expriences.</p>
            <div className={classes.actions}>
              <Button
                variant="contained"
                onClick={() => (document.location.href = '/my-page')}
                color="primary"
                className={classes.button}
                endIcon={<PersonIcon />}
              >
                My page
              </Button>

              <Button
                variant="contained"
                onClick={() => (document.location.href = '/')}
                color="primary"
                className={classes.button}
                endIcon={<MenuBookIcon />}
              >
                Find events
              </Button>
            </div>
          </FormGroup>
        </div>
      </form>
      <Dialog
        open={values.open}
        onClose={handleDialogClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            margin: 10,
          },
        }}
      >
        <DialogTitle id="scroll-dialog-title">Terms of Use</DialogTitle>
        <DialogContent dividers={true}>
          <Button onClick={handleDownload} color="primary">
            Download all Terms of Use
          </Button>

          <h3>Five rules to remember</h3>
          <ol style={{ margin: 0, paddingInlineStart: 15 }}>
            <li>
              If you forget an event and the guide can document they waited 15
              minutes, by sending you a SMS and email reminder 15 minutes apart,
              then we pay the guide 50% as a no show fee.
            </li>
            <li>
              All upfront payments were guides buy tickets or other things on
              your behalf, are not refundable
            </li>
            <li>
              If you cancel an event in time, we refund the full payment, minus
              any upfront payments.
            </li>
            <li>
              All refunds are payed as a one year gift card,that can be used for
              any new event.
            </li>
            <li>
              If the guide has to cancel the tour due to sickness or other
              circumstances out of their control, then the guide is no longer
              entitled to payment for the tour that was booked or any upfront
              costs they paid.
            </li>
          </ol>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
          <Button onClick={handleDialogAccept} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Signup
