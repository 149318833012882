import React, { Component } from 'react'
import { withCookies } from 'react-cookie'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'

const domain = document.location.hostname
const manager = 'Olio PI ApS'

const cookiePrivacyLevel = domain + ':privacy-level'

const styles = (theme) => ({
  container: {
    padding: 10,
    width: '100%',
    minHeight: 480,
    overflow: 'hidden',
  },
  paper: {
    height: 400,
    minHeight: 400,
    maxWidth: 400,
    marginLeft: '2%',
    width: '96%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: 'hidden',
  },
  header: {
    margin: 0,
  },
  slider: {
    color: '#999999',
    marginLeft: '10%',
    width: '80%',
  },
  info: {
    border: 0,
    padding: 0,
    margin: 0,
    marginTop: 10,
    overflow: 'auto',
    minHeight: 200,
    width: '100%',
  },
  list: {
    margin: 0,
    marginTop: 10,
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: 200,
    width: '100%',
  },
  cookie: {
    border: '1px solid #DDDDDD',
    borderRadius: 5,
    marginRight: 10,
    marginBottom: 10,
  },
  cookieHeader: {
    display: 'flex',
    backgroundColor: '#FFC0CB',
    padding: 5,
  },
  cookieHeaderTitle: {
    flex: 1,
  },
  cookieBody: {
    padding: 5,
  },
  storage: {
    border: '1px solid #DDDDDD',
    borderRadius: 5,
    marginRight: 10,
    marginBottom: 10,
  },
  storageHeader: {
    display: 'flex',
    backgroundColor: '#FFC0CB',
    padding: 5,
  },
  storageHeaderTitle: {
    flex: 1,
  },
  storageBody: {
    padding: 5,
  },
  checkbox: {},
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
    zIndex: 100,
    borderRadius: 20,
    paddingTop: 10,
    fontWeight: 'bold',
    backgroundColor: '#DCE775',
  },
})

const values = {
  cookieList: [
    {
      name: cookiePrivacyLevel,
      level: 2,
      manager,
      purpose: 'Your cookie accept level',
      domains: domain,
      expires: '',
    },
  ],
  storageList: [
    {
      name: domain + ':history',
      manager,
      purpose: 'App location history',
      domains: domain,
      expires: '',
    },
    {
      name: domain + ':data',
      manager,
      purpose: 'App user and session data',
      domains: domain,
      expires: '',
    },
    {
      name: domain + ':install-prompt',
      manager,
      purpose: 'Last install prompt answer',
      domains: domain,
      expires: '',
    },
  ],
}

const levels = [
  {
    value: 1,
    label: 'Required',
  },
  {
    value: 2,
    label: 'Preference',
  },
  {
    value: 3,
    label: 'Statistics',
  },
  {
    value: 4,
    label: 'Marketing',
  },
]

class Privacy extends Component {
  state = {
    open: false,
    info: true,
    data: false,
    list: false,
    level: 4,
    dialog: false,
    dialogCancelButton: true,
    dialogTitle: '',
    dialogMessage: '',
    dialogCancel: undefined,
    dialogConfirm: undefined,
  }

  componentWillUnmount() {}

  componentDidMount() {
    if (this.props.onMount) this.props.onMount(this)
    let level = 0
    try {
      level = parseInt(this.getCookie(cookiePrivacyLevel))
    } catch {}
    if (level > 0) {
      // console.log('Accepted cookie privacy level',level)
      this.setState({ level })
    } else {
      this.show()
    }
  }

  showMessageDialog = (title, message, confirmCallback, cancelCallback) => {
    this.setState({
      dialog: true,
      dialogTitle: title,
      dialogMessage: message,
      dialogCancel: cancelCallback,
      dialogConfirm: confirmCallback,
    })
  }

  handleCloseMessageDialog = (event) => {
    this.setState({ dialog: false, dialogCancel: null, dialogConfirm: null })
  }

  handleCancelMessageDialog = (event) => {
    this.setState({ dialog: false })
    if (this.state.dialogCancel) this.state.dialogCancel()
  }

  handleConfirmMessageDialog = (event) => {
    this.setState({ dialog: false })
    if (this.state.dialogConfirm) {
      this.state.dialogConfirm()
    }
  }

  show = () => {
    this.setState({ open: true })
  }

  hide = () => {
    this.setState({ open: false })
  }

  getCookie = (name) => {
    const { cookies } = this.props
    return cookies.get(name)
  }

  setCookie = (name, value) => {
    const { cookies } = this.props
    // Make cookie available to any path /
    cookies.set(name, value, { path: '/' })
  }

  removeCookie = (name) => {
    console.log('Remove cookie ', name)
    const { cookies } = this.props
    cookies.remove(name, { path: '/' })
    let index = values.cookieList.findIndex((item) => item.name === name)
    if (index >= 0) values.cookieList.splice(index, 1)
    this.forceUpdate()
  }

  removeStorage = (name) => {
    if (localStorage) {
      console.log('Remove local storage item ', name)
      localStorage.removeItem(name)
      let index = values.storageList.findIndex((item) => item.name === name)
      if (index >= 0) values.storageList.splice(index, 1)
      this.forceUpdate()
    }
  }

  removeChecked = () => {
    let self = this
    console.log('Remove checked items and cookies')
    Object.keys(self.state).forEach((key) => {
      if (key.startsWith('storage-')) {
        if (self.state[key]) {
          self.removeStorage(key.substring(8))
          self.setState({ key: false })
        }
      }
      if (key.startsWith('cookie-')) {
        if (self.state[key]) {
          self.removeCookie(key.substring(7))
          self.setState({ key: false })
        }
      }
    })
  }

  handleRemoveChecked = (event) => {
    let self = this

    let text = []
    text.push(
      <div key={text.length}>
        Delete selected local browser data and cookies?
      </div>,
    )
    Object.keys(self.state).forEach((key) => {
      if (key.startsWith('storage-')) {
        text.push(<div key={text.length}>Item {key.substring(8)}</div>)
      }
      if (key.startsWith('cookie-')) {
        text.push(<div key={text.length}>Cookie {key.substring(7)}</div>)
      }
    })

    this.showMessageDialog('Delete browser data', text, this.removeChecked)
  }

  handleCheckboxChange = (name) => (event) => {
    this.setState({ ...this.state, [name]: event.target.checked })
  }

  toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    this.setState({ ...this.state, open })
  }

  showCookiePrivacy = (event) => {
    this.setState({ info: false, data: false, list: true })
  }

  showDataProtection = (event) => {
    this.setState({ info: false, data: true, list: false })
  }

  showGDPR = (event) => {
    this.setState({ info: true, data: false, list: false })
  }

  handleAccept = (event) => {
    this.setCookie(cookiePrivacyLevel, this.state.level)
    this.hide()
  }

  render() {
    const { classes, cookies } = this.props

    // Get all cookie name and value object
    const cookie = cookies.getAll()
    // console.log(cookie)

    const valuetext = (value) => {
      return value
    }

    let localStorageKeys = []
    if (localStorage) localStorageKeys = Object.keys(localStorage)

    // Combine values.storageList with any local storage keys
    let storageList = JSON.parse(JSON.stringify(values.storageList))
    let storageListKeys = storageList.map((item) => item.name)
    localStorageKeys.forEach((key) => {
      if (!storageListKeys.includes(key)) {
        storageList.push({
          name: key,
          domains: domain,
        })
      }
    })

    const renderLocalStorage = (name, index) => {
      let value = localStorage.getItem(name)
      let size = 0
      if (value) size = value.length // Math.floor(value.length / 1024); // Kb
      let info = storageList[index]
      if (info) {
        return (
          <div key={'storage' + index} className={classes.storage}>
            <div className={classes.storageHeader}>
              <div className={classes.storageHeaderTitle}>{name}</div>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                className={classes.checkbox}
                checked={
                  this.state['storage-' + name] !== undefined
                    ? this.state['storage-' + name]
                    : false
                }
                onChange={this.handleCheckboxChange('storage-' + name)}
                value={name}
              />
            </div>
            <div className={classes.storageBody}>
              <div style={{ display: info.manager ? 'block' : 'none' }}>
                Manager: {info.manager}
              </div>
              <div style={{ display: info.purpose ? 'block' : 'none' }}>
                Purpose: {info.purpose}
              </div>
              <div style={{ display: info.domains ? 'block' : 'none' }}>
                Domains: {info.domains}
              </div>
              <div style={{ display: info.expires ? 'block' : 'none' }}>
                Expires: {info.expires}
              </div>
              <div>Size: {size} b</div>
            </div>
          </div>
        )
      }
      return (
        <div key={'storage' + index}>
          <div className={classes.storageHeader}>{name}</div>
          <div>Size: {size} b</div>
        </div>
      )
    }
    // Combine values.cookieList with all cookies found
    let cookieList = JSON.parse(JSON.stringify(values.cookieList))
    let cookieListKeys = cookieList.map((item) => item.name)
    Object.keys(cookie).forEach((key) => {
      if (!cookieListKeys.includes(key)) {
        cookieList.push({
          name: key,
          manager: '',
          purpose: '',
          domains: domain,
          expires: '',
        })
      }
    })

    //console.log('Cookie',cookie)
    // console.log('Cookie list',cookieList)
    //console.log('Function cookies',  Object.keys(cookie).filter( x => (cookieList[x] && cookieList[x].level === 2) ))

    const renderCookie = (info) => {
      let value = cookie[info.name]
      // console.log(info);
      if (info) {
        return (
          <div key={'cookie' + info.name} className={classes.cookie}>
            <div className={classes.cookieHeader}>
              <div className={classes.cookieHeaderTitle}>{info.name}</div>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                className={classes.checkbox}
                checked={
                  this.state['cookie-' + info.name] !== undefined
                    ? this.state['cookie-' + info.name]
                    : false
                }
                onChange={this.handleCheckboxChange('cookie-' + info.name)}
                value={info.name}
              />
            </div>
            <div className={classes.cookieBody}>
              <div style={{ display: info.manager ? 'block' : 'none' }}>
                Manager: {info.manager}
              </div>
              <div style={{ display: info.purpose ? 'block' : 'none' }}>
                Purpose: {info.purpose}
              </div>
              <div style={{ display: info.domains ? 'block' : 'none' }}>
                Domains: {info.domains}
              </div>
              <div style={{ display: info.expires ? 'block' : 'none' }}>
                Expires: {info.expires}
              </div>
              <div>Value: {value}</div>
            </div>
          </div>
        )
      }
      return (
        <div key={'cookie' + info.name}>
          <div className={classes.cookieHeader}>{info.name}</div>
          <div className={classes.cookieBody}>
            <div>Value: {value}</div>
          </div>
        </div>
      )
    }

    // console.log(cookieIndex)
    return (
      <Drawer
        classes={{ paper: classes.paper }}
        id="privacy"
        anchor="bottom"
        open={this.state.open}
        onClose={this.toggleDrawer(false)}
      >
        <div className={classes.container}>
          <div
            className={classes.info}
            style={{ display: this.state.info ? 'block' : 'none' }}
          >
            <h3 className={classes.header}>Data protection (GDPR)</h3>
            <Typography id="gdpr" gutterBottom>
              We only collect and use your personal information when required
              for the services you request from us. You can always request to
              have your private data deleted in our app, but by law we are
              required to keep payment transaction information for 5 years.
            </Typography>
          </div>
          <div
            className={classes.list}
            style={{ display: this.state.data ? 'block' : 'none' }}
          >
            <h3 className={classes.header}>Data stored in your browser</h3>
            <div>
              {storageList.map((item, index) =>
                renderLocalStorage(item.name, index),
              )}
            </div>
          </div>
          <div
            className={classes.list}
            style={{ display: this.state.list ? 'block' : 'none' }}
          >
            <h3 className={classes.header}>Cookies stored in your browser</h3>
            {cookieList
              .filter((item) => item.level === 1)
              .map((item) => renderCookie(item))}
            <h3>Function cookies</h3>
            {cookieList
              .filter((item) => item.level === 2)
              .map((item) => renderCookie(item))}
            <h3>Statistic cookies</h3>
            {cookieList
              .filter((item) => item.level === 3)
              .map((item) => renderCookie(item))}
            <h3>Marketing cookies</h3>
            {cookieList
              .filter((item) => item.level === 4)
              .map((item) => renderCookie(item))}
          </div>
          <div style={{ marginTop: '10px' }}>
            <h3 className={classes.header}>Accepted cookie level</h3>
            <Button
              className={classes.rightButton}
              style={{ marginTop: -30 }}
              onClick={this.handleAccept}
              color="primary"
            >
              Accept
            </Button>
          </div>
          <div>
            <Slider
              onChange={(event, value) => this.setState({ level: value })}
              value={this.state.level}
              step={1}
              min={1}
              max={4}
              getAriaValueText={valuetext}
              aria-labelledby="cookie-consent-slider"
              valueLabelDisplay="off"
              marks={levels}
              className={classes.slider}
            />
          </div>
          <div style={{ marginTop: '20px' }}>
            <Button
              className={classes.leftButton}
              onClick={this.showGDPR}
              color="default"
            >
              GDPR
            </Button>
            <Button
              className={classes.leftButton}
              onClick={this.showDataProtection}
              color="default"
            >
              Data
            </Button>
            <Button
              className={classes.leftButton}
              onClick={this.showCookiePrivacy}
              color="default"
            >
              Cookies
            </Button>
            <Button
              className={classes.leftButton}
              onClick={this.handleRemoveChecked}
              color="default"
            >
              Delete
            </Button>
          </div>
        </div>
        <Dialog
          open={this.state.dialog}
          onClose={this.handleCloseMessageDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.dialogTitle}
          </DialogTitle>
          <DialogContent>
            <div>{this.state.dialogMessage}</div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCancelMessageDialog}
              color="primary"
              style={{
                display: this.state.dialogCancelButton ? 'block' : 'none',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleConfirmMessageDialog}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Drawer>
    )
  }
}

Privacy.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withCookies(withStyles(styles)(Privacy))
