import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogContent: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}))
function ConfirmDialog(props) {
  const classes = useStyles()

  const {
    title,
    content,
    onClose,
    onConfirm,
    value: valueProp,
    open,
    ...other
  } = props
  const [value, setValue] = React.useState(valueProp)

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp)
    }
  }, [valueProp, open])

  const handleCancel = () => {
    if (onClose) onClose()
  }

  const handleConfirm = () => {
    if (onClose) onClose(value)
  }

  return (
    <Dialog maxWidth="xs" aria-labelledby={props.id} open={open} {...other}>
      <DialogTitle id={props.id}>{title}</DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        {content}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleCancel}
          color="primary"
          style={{ display: props.type === 'alert' ? 'none' : 'block' }}
        >
          Cancel
        </Button>
        <Button onClick={onConfirm ? onConfirm : handleConfirm} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  type: PropTypes.string, // alert, confirm, prompt
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string,
}

export default ConfirmDialog
