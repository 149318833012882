import React, { useRef, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
//import MailIcon from '@material-ui/icons/Mail';
//import SmsIcon from '@material-ui/icons/Sms';
//import ChatIcon from '@material-ui/icons/Chat';
//import StarRateIcon from '@material-ui/icons/StarRate';
//import StarOutlineIcon from '@material-ui/icons/StarOutline';

import List from '@material-ui/core/List'
//import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
// import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person'

import '../App.css'
import { getUser, getSession, fetchWithTimeout } from '../utils'
// import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  form: {
    width: '100vw',
    height: '100vh',
  },
  header: {
    width: '100%',
    textAlign: 'center',
  },
  box: {
    flex: 1,
    minHeight: 420,
    margin: 10,
    padding: 10,
    border: '1px solid #DDDDDD',
    borderRadius: 20,
    boxShadow: '5px 5px #EEEEEE',
    backgroundColor: '#FFFFFF',
  },
  actions: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    margin: 5,
    flex: 1,
  },
  button: {
    margin: 4,
    width: 100,
    borderRadius: 20,
  },
  anchor: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  blogPostList: {
    margin: 0,
    padding: 0,
  },
  blogPostListItem: {
    width: '100%',
    border: '1px solid #DDDDDD',
    borderRadius: 5,
    padding: 5,
  },
  blogPostListItemLabel: {
    display: 'inline-block',
    width: 50,
  },
}))

function Meetup(props) {
  const formEl = useRef(null)

  let { eventId } = useParams()

  const classes = useStyles()

  // eslint-disable-next-line
  const [eventList, setEventList] = React.useState([])
  // eslint-disable-next-line
  const [eventAgenda, setEventAgenda] = React.useState([])

  // eslint-disable-next-line
  const user = getUser()
  const session = getSession()

  const getEventList = useCallback(async (userId, eventId) => {
    if (!userId) return
    let res = await fetchWithTimeout(
      '/api/v1/user/' + userId + '/events' + (eventId ? '/' + eventId : ''),
      {
        headers: {
          Authorization: 'Bearer ' + session.token,
        },
      },
    )
    if (res && res.status === 200) {
      let json = await res.json()
      // Store event values before we start editing them
      setEventList(json.eventList)
      // console.log('Event',JSON.stringify(json.event,null,2));
    }
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line
  const getEventAgenda = useCallback(async (eventId) => {
    if (!eventId) return
    let res = await fetchWithTimeout('/api/v1/events/' + eventId + '/agenda', {
      headers: {
        Authorization: 'Bearer ' + session.token,
      },
    })
    if (res && res.status === 200) {
      let json = await res.json()
      // Store event values before we start editing them
      setEventAgenda(json.agenda)
      // console.log('EventAgenda', JSON.stringify(json.agenda,null,2));
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (user.id) {
      getEventList(user.id, eventId)
    }
  }, [getEventList, user.id, eventId])

  const agendaList = () => (
    <div className={classes.agendaList}>
      <List>
        {eventAgenda.map((agendaItem, index) => {
          return (
            <ListItem button key={'agenda-item-' + index}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              {agendaItem.title}
            </ListItem>
          )
        })}
      </List>
    </div>
  )

  return (
    <div className={classes.root}>
      <form
        ref={formEl}
        id="event"
        className={classes.form}
        noValidate
        autoComplete="off"
      >
        <FormGroup className={classes.box} style={{ paddingBottom: 50 }}>
          <h2 className={classes.header}>My events</h2>

          <List>
            {eventList.map((event, index) => {
              return (
                <ListItem button key={'event-' + index}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <h3>{event.title}</h3>
                  <p>
                    Date: {event.startdate}
                    <br />
                    Time: {event.starttime}-{event.endtime}
                  </p>
                  <p>
                    Topic: {event.topic}
                    <br />
                    {event.type ? 'Type: ' + event.type : ''}
                  </p>
                </ListItem>
              )
            })}
          </List>
          <div style={{ display: eventAgenda.length === 0 ? 'none' : 'block' }}>
            {agendaList()}
          </div>
        </FormGroup>
      </form>
    </div>
  )
}

export default Meetup
