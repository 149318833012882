import React, { useState, useContext, useEffect } from 'react'
// import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
// Icons
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import FavoriteIcon from '@material-ui/icons/Favorite'
// import LocationOnIcon from '@material-ui/icons/LocationOn';
import MenuIcon from '@material-ui/icons/Menu'
import VideocamIcon from '@material-ui/icons/Videocam'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import LanguageIcon from '@material-ui/icons/Language'
import HowToRegIcon from '@material-ui/icons/HowToReg'
//import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import EventIcon from '@material-ui/icons/Event'
import CategoryIcon from '@material-ui/icons/Category'
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail'
// import Slide from '@material-ui/core/Slide';
// import RestoreIcon from '@material-ui/icons/Restore';
import PersonIcon from '@material-ui/icons/Person'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Privacy from '../privacy'
import ConfirmOptionsDialog from '../confirm-options-dialog'
import { Context } from '../../context/Context'
import { hasValidSession } from '../../utils'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import QueryStatsIcon from '@mui/icons-material/QueryStats'

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 1000,
    borderTop: '1px solid #D0D1CB',
  },
  list: {
    width: 250,
    paddingLeft: 10,
    paddingRight: 10,
  },
  fullList: {
    width: 'auto',
  },
})

// props.app is reference to App.js component
export default function Navigation(props) {
  const { user } = props
  const context = useContext(Context)

  // Read locale translations from react-intl
  const intl = useIntl()
  const t = (id, defaultMessage) =>
    intl.formatMessage({
      id,
      defaultMessage: defaultMessage ? defaultMessage : id,
    })

  const isLoggedIn = hasValidSession()
  // useState variables accessible access multiple function calls
  const noOperation = () => {}
  const [privacyDialog, setPrivacyDialog] = useState(() => noOperation)
  const [localeDialogOpen, setLocaleDialogOpen] = useState(false)
  const [left, setLeft] = useState(false)
  const [right, setRight] = useState(false)
  const [topics, setTopics] = useState(
    context.state.search.topics ? context.state.search.topics : {},
  )
  const [value, setValue] = useState(0)
  const [showSearchFilters, setShowSearchFilters] = useState(
    document.location.href.indexOf('search=true') >= 0 ? true : false,
  )
  //  eslint-disable-next-line
  const [showProducts, setShowProducts] = useState(
    document.location.pathname.indexOf('/products/') === 0 ? true : false,
  )

  const drawerLeftRef = React.createRef(null)
  const drawerRightRef = React.createRef(null)

  const classes = useStyles()

  const showLocaleDialog = () => {
    setLocaleDialogOpen(true) // this state will have changed on next render in useEffect above
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    if (anchor === 'left') setLeft(open)
    if (anchor === 'right') setRight(open)
  }

  const handleTopic = (topic) => (event) => {
    // clone topics object
    let newTopics = JSON.parse(JSON.stringify(topics))
    if (newTopics[topic]) delete newTopics[topic]
    else newTopics[topic] = true
    setTopics(newTopics)
  }

  const menuList = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem
          button
          key="login"
          onClick={() => handleLogin()}
          style={{ display: !isLoggedIn ? 'inline-flex' : 'none' }}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </ListItem>
        <ListItem
          button
          key="signup"
          onClick={() => handleSignup()}
          style={{ display: !isLoggedIn ? 'inline-flex' : 'none' }}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Signup" />
        </ListItem>
        <ListItem
          button
          key="mypage"
          onClick={() => handleMyPage()}
          style={{ display: isLoggedIn ? 'inline-flex' : 'none' }}
        >
          <ListItemIcon>
            <HowToRegIcon />
          </ListItemIcon>
          <ListItemText primary="My page" />
        </ListItem>
        <ListItem
          button
          key="logout"
          onClick={() => handleLogout()}
          style={{ display: isLoggedIn ? 'inline-flex' : 'none' }}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
        <ListItem button key="privacy" onClick={() => privacyDialog.show()}>
          <ListItemIcon>
            <VpnKeyIcon />
          </ListItemIcon>
          <ListItemText primary="Privacy settings" />
        </ListItem>
        <ListItem button key="locale" onClick={() => showLocaleDialog()}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary="Change language" />
        </ListItem>
        <ListItem button key="contact" onClick={() => handleContact()}>
          <ListItemIcon>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary="Customer service" />
        </ListItem>
        <ListItem button key="mycalendar" onClick={() => handleCalendar()}>
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <ListItemText primary="My calendar" />
        </ListItem>
        <ListItem
          button
          key="admindashboard"
          style={{ display: user && user.role === 'admin' ? 'flex' : 'none' }}
          onClick={() => handleAdminDashboard()}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem
          button
          key="querybuilder"
          style={{
            display:
              user && (user.role === 'admin' || user.role === 'superuser')
                ? 'flex'
                : 'none',
          }}
          onClick={() => handleQueryBuilder()}
        >
          <ListItemIcon>
            <QueryStatsIcon />
          </ListItemIcon>
          <ListItemText primary="Query builder" />
        </ListItem>
      </List>
      <Divider />
      <List></List>
    </div>
  )

  const eventSearchFilterList = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <List style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          color="primary"
          startIcon={<SearchIcon />}
          onClick={onSearchFilters}
        >
          Search filters
        </Button>
        <IconButton onClick={onSearchClose}>
          <CloseIcon />
        </IconButton>
      </List>
      <Divider />
      <List>
        <h3>Guide profile</h3>
        <form>Age, gender, languages, timezone (close to me)</form>
      </List>
      <Divider />
      <List>
        <h3>Locations</h3>
        <form>Countries and Locations</form>
      </List>
      <Divider />
      <List>
        <h3>My favorite topics</h3>
        <form>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={topics['highlights'] === true}
                  onChange={handleTopic('highlights')}
                  icon={<FavoriteBorderIcon />}
                  checkedIcon={<FavoriteIcon />}
                  name="topics"
                />
              }
              label="Highlights"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={topics['history and culture'] === true}
                  onChange={handleTopic('history and culture')}
                  icon={<FavoriteBorderIcon />}
                  checkedIcon={<FavoriteIcon />}
                  name="topics"
                />
              }
              label="History and culture"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={topics['arts and museums'] === true}
                  onChange={handleTopic('arts and museums')}
                  icon={<FavoriteBorderIcon />}
                  checkedIcon={<FavoriteIcon />}
                  name="topics"
                />
              }
              label="Arts and museums"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={topics['design'] === true}
                  onChange={handleTopic('design')}
                  icon={<FavoriteBorderIcon />}
                  checkedIcon={<FavoriteIcon />}
                  name="topics"
                />
              }
              label="Design"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={topics['food and drinks'] === true}
                  onChange={handleTopic('food and drinks')}
                  icon={<FavoriteBorderIcon />}
                  checkedIcon={<FavoriteIcon />}
                  name="topics"
                />
              }
              label="Food and drinks"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={topics['literature, film and music'] === true}
                  onChange={handleTopic('literature, film and music')}
                  icon={<FavoriteBorderIcon />}
                  checkedIcon={<FavoriteIcon />}
                  name="topics"
                />
              }
              label="Literature, film and music"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={topics['architecture'] === true}
                  onChange={handleTopic('architecture')}
                  icon={<FavoriteBorderIcon />}
                  checkedIcon={<FavoriteIcon />}
                  name="topics"
                />
              }
              label="Architecture"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={topics['nature'] === true}
                  onChange={handleTopic('nature')}
                  icon={<FavoriteBorderIcon />}
                  checkedIcon={<FavoriteIcon />}
                  name="topics"
                />
              }
              label="Nature"
            />
          </FormGroup>
        </form>
      </List>
    </div>
  )

  const productSearchFilterList = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <List style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          color="primary"
          startIcon={<SearchIcon />}
          onClick={onSearchFilters}
        >
          Search filters
        </Button>
        <IconButton onClick={onSearchClose}>
          <CloseIcon />
        </IconButton>
      </List>
      <Divider />
      <List>
        <h3>Product categories</h3>
      </List>
      <Divider />
    </div>
  )

  // Show search event filters
  const onSearchEvent = (event) => {
    setShowProducts(false)
    toggleDrawer('left', true)(event)
    document.location.href = '/events'
  }

  // Show search product filters
  const onSearchProduct = (event) => {
    document.location.href = '/products/?search=true'
  }

  const onSearchClose = (event) => {
    context.saveSearch({
      topics,
    })
    setShowSearchFilters(false)
    toggleDrawer('left', false)(event)
  }

  // On page load
  useEffect(() => {
    console.log(document.location.href)
    if (showSearchFilters) {
      // Open left drawer and show either event or product search filters
      setLeft(true)
    }
    // eslint-disable-next-line
  }, [])

  // Open dialog to clear, load or save search filters
  const onSearchFilters = (event) => {
    context.saveSearch({
      topics,
    })
  }

  // eslint-disable-next-line
  const onNearby = (event) => {
    document.location.href = '/openstreetmap'
  }

  const onMeetup = (event) => {
    document.location.href = '/meetup'
  }

  const onMenu = (event) => {
    setRight(true)
  }

  const onChangeLocale = (value) => {
    setLocaleDialogOpen(false)
    // console.log('Change locale to',value);
    props.changeLocale(value)
  }

  const handleLogin = () => {
    document.location.href = '/login'
  }

  const handleSignup = () => {
    document.location.href = '/signup'
  }

  const handleContact = () => {
    document.location.href = '/contact'
  }

  const handleCalendar = () => {
    document.location.href = '/calendar'
  }

  const handleAdminDashboard = () => {
    document.location.href = '/admin/dashboard'
  }

  const handleQueryBuilder = () => {
    document.location.href = '/admin/queries'
  }

  const handleMyPage = () => {
    document.location.href = '/my-page'
  }

  const handleLogout = () => {
    context.onLogout()
    document.location.href = '/'
  }

  /*
  Warning: findDOMNode is deprecated in StrictMode. findDOMNode was passed an instance of Transition which is inside StrictMode. 
  Instead, add a ref directly to the element you want to reference. Learn more about using refs safely here: 
  https://reactjs.org/link/strict-mode-find-node
  */

  /*
  const SlideTransitionLeft = React.forwardRef((props, ref) => {
    return <Slide ref={ref} {...props} direction="left" />;
  });

  
  const SlideTransitionRight = React.forwardRef((props, ref) => {
    return <Slide ref={ref} {...props} direction="right" />;
  });
  */

  let locale = props.currentLocale()
  let options = props.localeOptions()

  return (
    <>
      <Privacy onMount={(dialog) => setPrivacyDialog(() => dialog)} />

      <ConfirmOptionsDialog
        open={localeDialogOpen}
        value={locale}
        options={options}
        onClose={onChangeLocale}
        title="Choose language"
        cancel="cancel"
        ok="ok"
      />

      <Drawer
        ref={drawerLeftRef}
        anchor="left"
        open={left}
        onClose={onSearchClose}
        //        TransitionComponent={SlideTransitionRight}
      >
        <div style={{ display: showProducts ? 'none' : 'block' }}>
          {eventSearchFilterList('left')}
        </div>
        <div style={{ display: showProducts ? 'block' : 'none' }}>
          {productSearchFilterList('left')}
        </div>
      </Drawer>

      <Drawer
        ref={drawerRightRef}
        anchor="right"
        open={right}
        onClose={toggleDrawer('right', false)}
        //        TransitionComponent={SlideTransitionLeft}
      >
        {menuList('right')}
      </Drawer>

      <BottomNavigation
        className={classes.root}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
          if (newValue === 'event') onSearchEvent(event)
          if (newValue === 'product') onSearchProduct(event)
          if (newValue === 'meetup') onMeetup(event)
          if (newValue === 'menu') onMenu(event)
        }}
        showLabels
      >
        <BottomNavigationAction
          label={t('app.product')}
          value="product"
          icon={<CategoryIcon />}
        />
        <BottomNavigationAction
          label={t('app.event')}
          value="event"
          icon={<EventIcon />}
        />
        <BottomNavigationAction
          label={t('app.meetup')}
          value="meetup"
          icon={<VideocamIcon />}
        />
        <BottomNavigationAction
          label={t('app.menu')}
          value="menu"
          icon={<MenuIcon />}
        />
      </BottomNavigation>
    </>
  )
}
