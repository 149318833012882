import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Button from '@material-ui/core/Button'
import VpnKey from '@material-ui/icons/VpnKey'
import '../App.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  box: {
    margin: 10,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    border: '1px solid #DDDDDD',
    borderRadius: 20,
    boxShadow: '5px 5px #EEEEEE',
    width: 300,
    backgroundColor: '#FFFFFF',
  },
  button: {
    margin: theme.spacing(2),
  },
}))

function ChangePassword(props) {
  const classes = useStyles()
  const [values, setValues] = React.useState({
    password1: '',
    password2: '',
    showPassword1: false,
    showPassword2: false,
    password1Hint: 'Please type secure password',
    password2Hint: 'Type same password again',
  })

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword1 = () => {
    setValues({ ...values, showPassword1: !values.showPassword1 })
  }

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault()
  }

  const handleClickShowPassword2 = () => {
    setValues({ ...values, showPassword2: !values.showPassword2 })
  }

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault()
  }

  const handleChangePassword = (event) => {
    alert('Change password')
    event.preventDefault()
  }

  return (
    <div className={classes.root}>
      <form className={classes.root} noValidate autoComplete="off">
        <div className={classes.box}>
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField,
            )}
          >
            <InputLabel htmlFor="password1">Password</InputLabel>
            <Input
              id="password1"
              type={values.showPassword1 ? 'text' : 'password'}
              value={values.password1}
              onChange={handleChange('password1')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password1 visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword1}
                  >
                    {values.showPassword1 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              aria-describedby="password-helper-text"
              inputProps={{
                'aria-label': 'password1',
              }}
            />
            <FormHelperText id="password-helper-text">
              {values.password1Hint}
            </FormHelperText>
          </FormControl>
          <FormControl
            className={clsx(
              classes.margin,
              classes.withoutLabel,
              classes.textField,
            )}
          >
            <InputLabel htmlFor="password2">Password</InputLabel>
            <Input
              id="password2"
              type={values.showPassword2 ? 'text' : 'password'}
              value={values.password2}
              onChange={handleChange('password2')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password2 visibility"
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword2}
                  >
                    {values.showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              aria-describedby="password-helper-text"
              inputProps={{
                'aria-label': 'password',
              }}
            />
            <FormHelperText id="password-helper-text">
              {values.password2Hint}
            </FormHelperText>
          </FormControl>
          <Button
            variant="contained"
            onClick={handleChangePassword}
            color="primary"
            className={classes.button}
            endIcon={<VpnKey />}
          >
            Change password
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ChangePassword
