import createDataContext from './createDataContext'
import features from './features.config.js'

import {
  getLocalStorageJson,
  setLocalStorageJson,
  getOrder,
  setOrder,
  getLocale,
  setLocale,
} from '../utils.js'

let localStorageKey = document.location.hostname + ':data'

const initialState = {
  user: undefined,
  session: undefined,
  location: '',
  locale: getLocale(),
  // Search text and filters 
  search: {},
  // Read order from local storage or create empty order object
  order: getOrder(), 

  // addon module features
  features
  
}


const userReducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return initialState
    case 'LOGIN':
      return {
        ...state,
        user: action.payload.user,
        session: action.payload.session,
      }
    case 'LOGOUT':
      return {
        ...state,
        user: undefined,
        session: undefined,
      }
    case 'SAVE_FEATURES':
      return { ...state, features: action.payload }  
    case 'SAVE_LOCATION':
      return {
        ...state,
        location: action.payload,
      }
    case 'SAVE_SEARCH':
      return {
        ...state,
        search: action.payload,
      }
    case 'SAVE_ORDER':
      return {
        ...state,
        order: action.payload,
      }
    case 'SAVE_LOCALE':
      return {
        ...state,
        locale: action.payload,
      }
    default:
      return state
  }
}

const onInit = (dispatch) => {
  return async () => {
    console.log('Set initial Context state')
    dispatch({ type: 'INIT' })
  }
}

const onLogin = (dispatch) => {
  return async (json) => {
    console.log('Login')
    // console.log("Login response", JSON.stringify(json,null,2));
    // Set username to local storage
    const data = getLocalStorageJson(localStorageKey, {})
    data.user = json.user
    data.session = json.session
    setLocalStorageJson(localStorageKey, data)

    // Save username to context state - Is this even needed?
    dispatch({ type: 'LOGIN', payload: json })
  }
}

const onLogout = (dispatch) => {
  return async () => {
    console.log('Log out')

    // Clear local storage user and session, but keep shopping cart order information
    const data = getLocalStorageJson(localStorageKey, {})
    delete data.session
    delete data.user
    setLocalStorageJson(localStorageKey, data)

    // Clear currentUser state
    dispatch({ type: 'LOGOUT' })
  }
}

const saveFeatures = (dispatch) => {
  return async (featuresObject) => {
    const features = featuresObject ? featuresObject : initialState.features
    const updatedLocalStorageData = {
      ...getLocalStorageJson(localStorageKey, {}),
      features,
    }
    setLocalStorageJson(localStorageKey, updatedLocalStorageData)
    dispatch({ type: 'SAVE_FEATURES', payload: featuresObject })
  }
}


const saveLocation = (dispatch) => {
  return async (locationHistory) => {
    // console.log("Saving location history");

    //Set username to cookies
    // document.cookie = `locationHistory=${locationHistory}`;

    // Save location to context
    dispatch({ type: 'SAVE_LOCATION', payload: locationHistory })
  }
}

const saveSearch = (dispatch) => {
  return async (search) => {
    // Save search to context
    dispatch({ type: 'SAVE_SEARCH', payload: search })
  }
}

const saveOrder = (dispatch) => {
  return async (order) => {
    // Store order in local storage, if user leaves site without going through with the order
    setOrder(order)
    // Save order to context with amount, total, currency, description and order lines
    dispatch({ type: 'SAVE_ORDER', payload: order })
  }
}

const saveLocale = (dispatch) => {
  return async (locale) => {
    // Store locale in local storage, if user leaves site we remember their locale settings
    setLocale(locale)
    // Save order to context with amount, total, currency, description and order lines
    dispatch({ type: 'SAVE_LOCALE', payload: locale })
  }
}

const { Context, Provider } = createDataContext(
  userReducer,
  {
    onInit,
    onLogin,
    onLogout,
    saveFeatures,
    saveLocation,
    saveSearch,
    saveOrder,
    saveLocale,
  },
  initialState,
)

export { Context, Provider as ContextProvider }
